import { useSelector } from "react-redux";
import { Permissions } from "Data/Permissions";

export const usePermissions = () => {
  const userRole = useSelector((state: any) => state.user.role);
  const userPermissions = useSelector((state: any) => state.user.userPermissions);

  // const hasPermission = (permissionName: keyof typeof Permissions): boolean => {
  //   console.log(Permissions[permissionName])
  //   // return userPermissions?.includes(Permissions[permissionName]);
  //   return true;
  // };

  const hasPermission = (userPermissionss: any, requiredPermissions: any) => {
    if (!requiredPermissions || requiredPermissions.length === 0) return true;

    return requiredPermissions.every((permission: any) => userPermissionss.includes(permission));
  };

  return { hasPermission };
}