import axios from 'axios';
import request from './files/Request';
import * as Paths from './files/Paths';
import * as Headers from "./files/Headers";
import { toast } from 'react-toastify';

import i18n from '../i18n';

// Types
import { UserDetailOwn } from 'Types/User/UserType';
import { ListFilterType } from 'Types/Common/ListFilterType';
import { defaultPageAndSizeForAll } from 'Utils/Constants';

// get userInfo data with redux
export const getUserDetailOwn = async () => {
  try {
    const response = await axios.get(`${Paths.userDetail}/own`);
    if (response?.status === 401) return false;
    return response.data.body;
  } catch (error: any) {
    (error?.response?.status !== 401) && toast.error(i18n.t(`ERRORS.${error?.response?.data?.meta?.errorCode}`));
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.replace(`${process.env.PUBLIC_URL}/login`);
    }
  }
};

export const saveUserDetailOwn = async (userDetail: UserDetailOwn) => {
  return request.post(`${Paths.userDetail}/save/own`, userDetail);
};

// get user list
export const getUsers = async (userListFilter: ListFilterType, filterObj: any) => {
  const filterParams = {
    firstName: filterObj?.firstName,
    lastName: filterObj?.lastName,
    email: filterObj?.email,
    enabled: filterObj?.enabled
  };
  return request.get(
    `${Paths.user}/find-all/paginated`,
    {
      page: userListFilter.page,
      size: userListFilter.size,
      ...(userListFilter?.sort && {sort: userListFilter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getUsersAll = async () => {
  return request.get(
    `${Paths.user}/find-all/paginated`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const deleteUser = async (id: string) => {
  return request.delete(
    `${Paths.user}/delete/${id}`,
    {},
    {
      headers: Headers.headers,
    }
  );
};

export const addUser = async (user: any) => {
  return request.post(`${Paths.user}/save`, user, {}, {
    headers: Headers.headers,
  });
};


// ----------------------------------------------
// used in redux actions example
export const getUser = async (userId: string) => {
  try {
    const response = await axios.get(`https://jsonplaceholder.typicode.com/users/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('User fetch failed');
  }
};