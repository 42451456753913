import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form as AntForm, Select, Tooltip } from "antd";
import _ from "lodash";

// Services
import * as userGroupService from "Services/ManagementPanelServices/UserGroup";
import * as permissionService from "Services/ManagementPanelServices/Permission";

// Types
import { Permission } from "Types/ManagementPanel/Permission";

export default function AddUserGroup() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [permissionListData, setPermissionListData] = useState(
    [] as Permission[]
  );
  const [selectedPermissions, setSelectedPermissions] = useState([] as any[]);

  const addUserRole: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      permissions: selectedPermissions,
    };
    const { error }: any = await userGroupService.addUserGroup(newData);
    navigate(`${process.env.PUBLIC_URL}/management/user-group/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  const getPermissions = async () => {
    const { data, error }: any = await permissionService.getPermissionsAll();
    const updated = data?.body?.content?.map((perm: Permission) => {
      return {
        label: perm.name,
        value: perm.id,
      };
    });
    setPermissionListData(updated);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("UserPages.AddUserGroup")}
        parent={t("UserPages.ManagementPanel")}
        subParent={t("UserPages.UserGroupPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/management/user-group/list`}
        title={t("UserPages.AddUserGroup")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <AntForm
                  className="theme-form"
                  onFinish={addUserRole}
                  autoComplete="off"
                >
                  <Row>
                    <Col>
                      <Label>{t("Common.name")}</Label>
                      <AntForm.Item
                        name="name"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.UserPages.NameRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.name")} *`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>{t("Menu.Permissions")}</Label>
                      <AntForm.Item
                        name="permissions"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.UserPages.PermissionsRequired"
                            ),
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          className="w-100"
                          options={permissionListData}
                          placeholder={t("UserPages.selectPermission")}
                          maxTagCount="responsive"
                          onChange={(e, item: any) => {
                            let updated = item.map((itm: any) => {
                              return {
                                id: itm.value,
                                name: itm.label
                              }
                            })
                            setSelectedPermissions(updated);
                          }}
                          maxTagPlaceholder={(omittedValues) => (
                            <Tooltip
                              overlayStyle={{ pointerEvents: "none" }}
                              title={omittedValues
                                .map(({ label }) => label)
                                .join(", ")}
                            >
                              <span>{t("Common.hoverMore")}</span>
                            </Tooltip>
                          )}
                        />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.add")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/management/user-group/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </AntForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
