import React, { FormEvent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Btn, H3, Image, P } from 'AbstractElements';
import { dynamicImage } from 'Utils';
import { checkLoginAndNavigate } from 'Utils/Auth';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn, SignInAccount } from 'Utils/Constants';

// Services
import * as authService from 'Services/AuthService';

export default function SignInForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const toggle = () => setPasswordVisible(!isPasswordVisible);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (email && password) {
      try {
        const resp =  await authService.login({ email, password });
        toast.success(t("Login.loginSuccess"));
        localStorage.setItem("i18nextLng", "tr");
        localStorage.setItem("prtlb-token", resp.data.body.access_token);
        // localStorage.setItem("prtlb-refreshToken", resp.data.body.refresh_token);
        localStorage.setItem("login", JSON.stringify(true));
        navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
      } catch (error: any) {
        if(error?.response?.data.meta.status === "FAILURE") {
          toast.error(t(`ERRORS.${error?.response?.data?.meta?.errorCode}`));
        }
      }
    } else {
      toast.error(t("Login.pleaseEnterValidInfos"));
    }
  };

  useEffect(() => {
    // logged in control
    checkLoginAndNavigate(navigate, `${process.env.PUBLIC_URL}/dashboard/default`, `${process.env.PUBLIC_URL}/login`);
  }, [navigate])
  
  return (
    <div>
      <div>
        <Link className='logo' to={`${process.env.PUBLIC_URL}/dashboard/default`}>
          <Image className="img-fluid for-light" src={dynamicImage(`logos/letter-p__64x64.png`)} alt="looginpage" body={true} />
        </Link>
      </div>
      <div className="login-main">
        {/* <div>
          <Link className='logo' to={`${process.env.PUBLIC_URL}/dashboard/default`}>
            <Image className="img-fluid for-light" src={dynamicImage(`logos/black-yellow.png`)} alt="Portlab" body={true} style={{ height: "65px" }} />
          </Link>
        </div> */}
        <Form className="theme-form" onSubmit={handleSubmit}>
          <H3>{"Giriş Yap"}</H3>
          <P>{"Giriş yapmak için e-posta ve şifrenizi giriniz."}</P>
          <FormGroup>
            <Col><Label>{"E-Posta"}</Label></Col>
            <Input type="email" defaultValue={email} onChange={(event) => setEmail(event.target.value)} />
          </FormGroup>
          <FormGroup>
            <Col><Label>{"Şifre"}</Label></Col>
            <div className="form-input position-relative">
              <Input type={isPasswordVisible ? 'text' : 'password'} defaultValue={password} onChange={(event) => setPassword(event.target.value)} name="login[password]" />
              <div className='show-hide' onClick={toggle}>
                <span className={!isPasswordVisible ? 'show' : ''}></span>
              </div>
            </div>
          </FormGroup>
          <div className="mb-0 form-group">
            <div className="checkbox p-0">
              <Input id="checkbox1" type="checkbox" />
              <Label className="text-muted" htmlFor="checkbox1">{"Beni Hatırla"}</Label>
            </div>
            {/* <Link className="link" to={`${process.env.PUBLIC_URL}/auth/forget-password`}>{ForgotPassword}</Link> */}
            <div className="text-end mt-3">
              <Btn color='primary' className="btn-block w-100">{"Giriş"}</Btn>
            </div>
          </div>
        </Form>
      </div>
    </div >
  )
}
