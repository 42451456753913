import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href, defaultPageAndSize } from "Utils/Constants";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { P, UL, LI, Btn } from "AbstractElements";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import CommonModal from "Utils/CommonComponents/UiKitsCommon/CommonModal";
import { Spin, Tooltip } from "antd";
import FilterSelect from "Components/Filters/FilterSelect";
import FilterInput from "Components/Filters/FilterInput";

// Services
import * as inventoryService from "Services/DebitServices/Inventory";
import * as debitService from "Services/DebitServices/Debit";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { InventoryType } from "Types/Debit/InventoryType";
import { DebitType } from "Types/Debit/DebitType";

// Enums
import { ProductStates } from "Enums/Debit/ProductState";
import { ProductTypes } from "Enums/Debit/Product";

export default function Inventory() {
  const { t } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };
  const [inventoryListData, setInventoryListData] = useState(
    [] as InventoryType[]
  );

  const [inventoryListFilter, setInventoryListFilter] = useState(
    defaultPageAndSize as ListFilterType
  );
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [selectedDebitData, setSelectedDebitData] = useState({} as DebitType);
  const [description, setDescription] = useState<string>("");

  // Modals
  const [modalDebit, setModalDebit] = useState<boolean>(false);
  const toggleDebitModal = () => {
    setModalDebit(!modalDebit);
  };
  const dataDebitModal = {
    isOpen: modalDebit,
    header: true,
    toggler: toggleDebitModal,
    title: t("DebitPages.DebitAction"),
    size: "lg",
    bodyClass: "dark-modal",
  };

  const tableListColumns: TableColumn<any>[] = [
    {
      name: t("Common.productState"),
      sortField: "productState",
      selector: (row) => row.productState,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {row.productState
            ? t(
                `Common.${
                  ProductStates[row.productState as keyof typeof ProductStates]
                }`
              )
            : "-"}
        </P>
      ),
    },
    {
      name: t("Common.productType"),
      sortField: "productType",
      selector: (row) => row.productType,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {row.productType
            ? t(
                `Common.${
                  ProductTypes[row.productType as keyof typeof ProductTypes]
                }`
              )
            : "-"}
        </P>
      ),
    },
    {
      name: t("Common.stock"),
      sortField: "stock",
      selector: (row) => row.stock,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.stock}</P>,
    },
    {
      name: t("Common.controlPeriod"),
      sortField: "controlPeriod",
      selector: (row) => row.controlPeriod,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.controlPeriod}</P>,
    },
    {
      name: t("Common.createdDate"),
      sortField: "createdDate",
      width: "200px",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {dayjs(row.createdDate).format("DD-MM-YYYY HH:mm")}
        </P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "90px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="other">
              <Tooltip placement="bottom" title={t("DebitPages.DebitAction")}>
                <Link
                  onClick={() => {
                    toggleDebitModal();
                    setSelectedDebitData(row);
                  }}
                  to={``}
                >
                  {/* <i className="fa fa-chain"></i> */}
                  <i className="icon-shift-right"></i>
                </Link>
              </Tooltip>
            </LI>
            <LI className="edit">
              <Tooltip placement="bottom" title={t("Common.Edit")}>
                <Link
                  to={`${process.env.PUBLIC_URL}/debit/inventory/edit/${row.id}`}
                >
                  <i className="icon-pencil-alt" />
                </Link>
              </Tooltip>
            </LI>
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteInventory(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getInventories = async (inventoryListFilter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any = await inventoryService.getInventories(
      inventoryListFilter,
      filterObj
    );
    setLoadingTable(false);
    setInventoryListData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(false);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const handlePageChange = (page: number) => {
    setInventoryListFilter({
      ...inventoryListFilter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!inventoryListData.length) return;
    setInventoryListFilter({
      ...inventoryListFilter,
      size: newRowsPerPage,
    });
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getInventories({
      ...inventoryListFilter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteInventory = async (inventorydId: string) => {
    const { error }: any = await inventoryService.deleteInventory(inventorydId);
    toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const saveDebitAction = async () => {
    const { id, ...rest } = selectedDebitData;
    const newData = {
      ...rest,
      description: description,
    };
    const { error }: any = await debitService.saveDebitAction(newData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      getInventories(inventoryListFilter);
      toast.success(t("COMMON_MESSAGES.debitActionSuccessfully"));
      toggleDebitModal();
    }
  };

  useEffect(() => {
    getInventories(inventoryListFilter);
  }, [inventoryListFilter]);

  // Flter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getInventories(inventoryListFilter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getInventories(inventoryListFilter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.InventoryPageTitle")}
        parent={t("DebitPages.Debit")}
        title={t("DebitPages.InventoryPageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <a href={Href} onClick={handleFilterToggle}>
                      <div className="light-box">
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </div>
                    </a>
                    <Link
                      className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/debit/inventory/add`}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("DebitPages.AddInventory")}
                    </Link>
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">
                              {t("Common.productState")}
                            </Label>
                            <FilterSelect
                              inputName={"productState"}
                              setFilterObjFun={setFilterObj}
                              isStatus={false}
                              options={Object.keys(ProductStates).map((key) => {
                                return {
                                  text: t(
                                    `Common.${
                                      ProductStates[
                                        key as keyof typeof ProductStates
                                      ]
                                    }`
                                  ),
                                  value: `${key}`,
                                };
                              })}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.productType")}
                            </Label>
                            <FilterSelect
                              inputName={"productType"}
                              setFilterObjFun={setFilterObj}
                              isStatus={false}
                              options={Object.keys(ProductTypes).map((key) => {
                                return {
                                  text: t(
                                    `Common.${
                                      ProductTypes[
                                        key as keyof typeof ProductTypes
                                      ]
                                    }`
                                  ),
                                  value: `${key}`,
                                };
                              })}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.stock")}</Label>
                            <FilterInput
                              inputType={"number"}
                              inputName={"stock"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.controlPeriod")}
                            </Label>
                            <FilterInput
                              inputType={"number"}
                              inputName={"controlPeriod"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              className="me-3"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              className="me-3"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={inventoryListData ? inventoryListData : []}
                    columns={tableListColumns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={inventoryListFilter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText"),
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Debit Action Modal */}
      <CommonModal modalData={dataDebitModal}>
        <>
          {selectedDebitData.productType === "DISPOSABLE" && (
            <Row>
              <Col>
                <Label>{t("Common.quantity")}</Label>
                <input
                  className="form-control"
                  type="number"
                  placeholder={`${t("Common.quantity")} *`}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Label>{t("Common.description")}</Label>
              <textarea
                className="form-control"
                cols={30}
                rows={5}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end mt-3">
                <Btn color="primary" onClick={() => saveDebitAction()}>
                  {t("DebitPages.DebitAction")}
                </Btn>
              </div>
            </Col>
          </Row>
        </>
      </CommonModal>
    </div>
  );
}
