import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href, defaultPageAndSize } from "Utils/Constants";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { P, UL, LI, Btn } from "AbstractElements";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Spin, Tooltip } from "antd";
import CommonModal from "Utils/CommonComponents/UiKitsCommon/CommonModal";
import FilterInput from "Components/Filters/FilterInput";
import FilterSelect from "Components/Filters/FilterSelect";

// Services
import * as debitService from "Services/DebitServices/Debit";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { DebitType } from "Types/Debit/DebitType";

// Enums
import { ProductTypes } from "Enums/Debit/Product";

export default function Debit() {
  const { t } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };
  const [debitListData, setDebitListData] = useState([] as DebitType[]);

  const [debitListFilter, setDebitListFilter] = useState(
    defaultPageAndSize as ListFilterType
  );
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [selectedDebitData, setSelectedDebitData] = useState({} as DebitType);
  const [description, setDescription] = useState<string>("");

  const [modalReturnDebit, setModalReturnDebit] = useState<boolean>(false);
  const toggleReturnDebitModal = () => {
    setModalReturnDebit(!modalReturnDebit);
  };
  const dataReturnDebitModal = {
    isOpen: modalReturnDebit,
    header: true,
    toggler: toggleReturnDebitModal,
    title: t("DebitPages.DebitReturnAction"),
    size: "lg",
    bodyClass: "dark-modal",
  };

  const tableListColumns: TableColumn<any>[] = [
    {
      name: t("Common.productType"),
      sortField: "productType",
      selector: (row) => row.productType,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {row.productType
            ? t(
                `Common.${
                  ProductTypes[row.productType as keyof typeof ProductTypes]
                }`
              )
            : "-"}
        </P>
      ),
    },
    {
      name: t("Common.quantity"),
      sortField: "quantity",
      selector: (row) => row.quantity,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.quantity}</P>,
    },
    {
      name: t("Common.maintenance"),
      sortField: "maintenance",
      selector: (row) => row.maintenance,
      width: "90px",
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker text-center w-100">
          {row.maintenance ? (
            <i className="fa fa-check-circle-o text-success fs-5"></i>
          ) : (
            <i className="fa fa-times-circle-o text-danger fs-5"></i>
          )}
        </P>
      ),
    },
    {
      name: t("Common.createdDate"),
      sortField: "createdDate",
      width: "200px",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {dayjs(row.createdDate).format("DD-MM-YYYY HH:mm")}
        </P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "90px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="other">
              <Tooltip
                placement="bottom"
                title={t("DebitPages.DebitReturnAction")}
              >
                <Link
                  onClick={() => {
                    toggleReturnDebitModal();
                    setSelectedDebitData(row);
                  }}
                  to={``}
                >
                  {/* <i className="fa fa-chain-broken"></i> */}
                  <i className="icon-shift-left"></i>
                </Link>
              </Tooltip>
            </LI>
            <LI className="edit">
              <Tooltip placement="bottom" title={t("Common.Edit")}>
                <Link
                  to={`${process.env.PUBLIC_URL}/debit/main/edit/${row.id}`}
                >
                  <i className="icon-pencil-alt" />
                </Link>
              </Tooltip>
            </LI>
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteDebit(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getDebits = async (debitListFilter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any = await debitService.getDebits(
      debitListFilter,
      filterObj
    );
    setLoadingTable(false);
    setDebitListData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(false);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const handlePageChange = (page: number) => {
    setDebitListFilter({
      ...debitListFilter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!debitListData.length) return;
    setDebitListFilter({
      ...debitListFilter,
      size: newRowsPerPage,
    });
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getDebits({
      ...debitListFilter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteDebit = async (debitId: string) => {
    const { error }: any = await debitService.deleteDebit(debitId);
    toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const saveDebitReturnAction = async () => {
    const { id, ...rest } = selectedDebitData;
    const newData = {
      ...rest,
      description: description,
    };
    const { error }: any = await debitService.saveDebitReturnAction(newData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      getDebits(debitListFilter);
      toast.success(t("COMMON_MESSAGES.debitReturnActionSuccessfully"));
      toggleReturnDebitModal();
    }
  };

  useEffect(() => {
    getDebits(debitListFilter);
  }, [debitListFilter]);

  // Flter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getDebits(debitListFilter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getDebits(debitListFilter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.DebitPageTitle")}
        parent={t("DebitPages.Debit")}
        title={t("DebitPages.DebitPageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <a href={Href} onClick={handleFilterToggle}>
                      <div className="light-box">
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </div>
                    </a>
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">
                              {t("Common.productType")}
                            </Label>
                            <FilterSelect
                              inputName={"productType"}
                              setFilterObjFun={setFilterObj}
                              isStatus={false}
                              options={Object.keys(ProductTypes).map((key) => {
                                return {
                                  text: t(
                                    `Common.${
                                      ProductTypes[
                                        key as keyof typeof ProductTypes
                                      ]
                                    }`
                                  ),
                                  value: `${key}`,
                                };
                              })}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.quantity")}
                            </Label>
                            <FilterInput
                              inputType={"number"}
                              inputName={"quantity"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.maintenance")}
                            </Label>
                            <FilterSelect
                              inputName={"maintenance"}
                              setFilterObjFun={setFilterObj}
                              isStatus={true}
                              options={[
                                { text: t("Common.Active"), value: "true" },
                                { text: t("Common.Passive"), value: "false" },
                              ]}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          {/* <Col>
                            <Label className="mb-2">{t("Common.createdDate")}</Label>
                            <FilterInput
                              inputType={"date"} // date & daterange
                              inputName={"createdDate"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col> */}
                          <Col>
                            <Label className="mb-2">
                              {t("Common.createdDate")}
                            </Label>
                            <FilterInput
                              inputType={"daterange"} // date & daterange
                              inputName={"createdDate"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              className="me-3"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              className="me-3"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={debitListData ? debitListData : []}
                    columns={tableListColumns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={debitListFilter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText"),
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Debit Return Action Modal */}
      <CommonModal modalData={dataReturnDebitModal}>
        <>
          <Row>
            <Col>
              <Label>{t("Common.description")}</Label>
              <textarea
                className="form-control"
                cols={30}
                rows={5}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end mt-3">
                <Btn color="primary" onClick={() => saveDebitReturnAction()}>
                  {t("DebitPages.DebitReturnAction")}
                </Btn>
              </div>
            </Col>
          </Row>
        </>
      </CommonModal>
    </div>
  );
}
