import Default from "../Pages/Dashboards/Default";
// import UserProfile from "../Pages/Apps/Users/UserProfile";
// import EditProfile from "../Pages/Apps/Users/EditProfile";

// Management Panel
import Users from "Pages/ManagementPanel/Users/List";
import AddUser from "Pages/ManagementPanel/Users/AddUser";
import UserGroups from "../Pages/ManagementPanel/UserGroups/List";
import AddUserGroup from "../Pages/ManagementPanel/UserGroups/AddUserGroup";
import Permissions from "Pages/ManagementPanel/Permissions/List";
import AddPermission from "Pages/ManagementPanel/Permissions/AddPermission";
// Profile
import UserProfile from "../Pages/Profile/ProfileInfo/index";
import EditProfile from "../Pages/Profile/ProfileEdit/index";
// Debit
import Brand from "Pages/Debit/Brand/List";
import AddBrand from "Pages/Debit/Brand/AddBrand";
import UpdateBrand from "Pages/Debit/Brand/UpdateBrand";
import Vehicle from "Pages/Debit/Vehicle/List";
import AddVehicle from "Pages/Debit/Vehicle/AddVehicle";
import UpdateVehicle from "Pages/Debit/Vehicle/UpdateVehicle";
import Inventory from "Pages/Debit/Inventory/List";
import AddInventory from "Pages/Debit/Inventory/AddInventory";
import UpdateInventory from "Pages/Debit/Inventory/UpdateInventory";
import Device from "Pages/Debit/Device/List";
import AddDevice from "Pages/Debit/Device/AddDevice";
import UpdateDevice from "Pages/Debit/Device/UpdateDevice";
import Disposable from "Pages/Debit/Disposable/List";
import AddDisposable from "Pages/Debit/Disposable/AddDisposable";
import UpdateDisposable from "Pages/Debit/Disposable/UpdateDisposable";
import Debit from "Pages/Debit/Debit/List";
import UpdateDebit from "Pages/Debit/Debit/UpdateDebit";
import VehicleClass from "Pages/Debit/VehicleClass/List";
import AddVehicleClass from "Pages/Debit/VehicleClass/AddVehicleClass";
import UpdateVehicleClass from "Pages/Debit/VehicleClass/UpdateVehicleClass";
// Crm
import CompanyList from "Pages/Crm/Company/List";
import ContactList from "Pages/Crm/Contact/List";
import AddContact from "Pages/Crm/Contact/AddContact";
import AddCompany from "Pages/Crm/Company/AddCompany";
import UpdateCompany from "Pages/Crm/Company/UpdateCompany";
import UpdateContact from "Pages/Crm/Contact/UpdateContact";
import AddProject from "Pages/Crm/Project/AddProject";
import ProjectList from "Pages/Crm/Project/List";
import UpdateProject from "Pages/Crm/Project/UpdateProject";
// Education
import DocumentList from "Pages/Education/Documents/List";
// Works
import WorkList from "Pages/Metriks/Works/Work/List";
import AddWork from "Pages/Metriks/Works/Work/AddWork";
import WorkOrderList from "Pages/Metriks/Works/WorkOrder/List";
// Experiments
import LaboratoryList from "Pages/Metriks/Laboratory/List";
import AddLaboratory from "Pages/Metriks/Laboratory/AddLaboratory";
import AnalysisMethodList from "Pages/Metriks/AnalysisMethods/List";
import AddAnalysisMethod from "Pages/Metriks/AnalysisMethods/AddAnalysisMethod";
import ExperimentList from "Pages/Metriks/Experiment/List";
import AddExperiment from "Pages/Metriks/Experiment/AddExperiment";
import UpdateExperiment from "Pages/Metriks/Experiment/UpdateExperiment";
import TableList from "Pages/Metriks/Tables/List";
import UpdateAnalysisMethod from "Pages/Metriks/AnalysisMethods/UpdateAnalysisMethod";
import UpdateLaboratory from "Pages/Metriks/Laboratory/UpdateLaboratory";
import AddTable from "Pages/Metriks/Tables/AddTable";
import UpdateTable from "Pages/Metriks/Tables/UpdateTable";
import UpdateWork from "Pages/Metriks/Works/Work/UpdateWork";
import AddOffer from "Pages/Metriks/Offers/AddOffer";
import UpdateOffer from "Pages/Metriks/Offers/UpdateOffer";
import OfferList from "Pages/Metriks/Offers/List";
import AddPlanning from "Pages/Metriks/Plannings/AddPlanning";
import WorkGroupList from "Pages/Metriks/Works/WorkGroup/List";
import AddWorkGroup from "Pages/Metriks/Works/WorkGroup/AddWorkGroup";
import UpdateWorkGroup from "Pages/Metriks/Works/WorkGroup/UpdateWorkGroup";
import WorkDetail from "Pages/Metriks/Works/Work/WorkDetail";


export const routes = [
  // Dashboard
  { path: `${process.env.PUBLIC_URL}/dashboard/default`, element: <Default />, permissions: ['view_dashboard'] },
  // Management Panel
  { path: `${process.env.PUBLIC_URL}/management/user-group/list`, element: <UserGroups />, permissions: [] },
  { path: `${process.env.PUBLIC_URL}/management/user-group/add`, element: <AddUserGroup />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/management/user/list`, element: <Users />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/management/user/add`, element: <AddUser />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/management/permission/list`, element: <Permissions />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/management/permission/add`, element: <AddPermission />, permissions: []  },
  // Debit
  { path: `${process.env.PUBLIC_URL}/debit/brand/list`, element: <Brand />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/brand/edit/:id`, element: <UpdateBrand />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/brand/add`, element: <AddBrand />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/vehicle/list`, element: <Vehicle />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/vehicle/add`, element: <AddVehicle />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/vehicle/edit/:id`, element: <UpdateVehicle />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/inventory/list`, element: <Inventory />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/inventory/add`, element: <AddInventory />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/inventory/edit/:id`, element: <UpdateInventory />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/device/list`, element: <Device />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/device/add`, element: <AddDevice />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/device/edit/:id`, element: <UpdateDevice />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/disposable/list`, element: <Disposable />, permissions: [] },
  { path: `${process.env.PUBLIC_URL}/debit/disposable/add`, element: <AddDisposable />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/disposable/edit/:id`, element: <UpdateDisposable />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/main/list`, element: <Debit />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/main/edit/:id`, element: <UpdateDebit />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/vehicle-class/list`, element: <VehicleClass />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/vehicle-class/add`, element: <AddVehicleClass />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/debit/vehicle-class/edit/:id`, element: <UpdateVehicleClass />, permissions: []  },
  // Profile
  { path: `${process.env.PUBLIC_URL}/profile/user-profile`, element: <UserProfile />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/profile/edit-profile`, element: <EditProfile />, permissions: []  },
  // CRM
  { path: `${process.env.PUBLIC_URL}/crm/company/list`, element: <CompanyList />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/crm/company/add`, element: <AddCompany />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/crm/company/edit/:id`, element: <UpdateCompany />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/crm/contact/list`, element: <ContactList />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/crm/contact/add`, element: <AddContact />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/crm/contact/edit/:id`, element: <UpdateContact />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/crm/project/list`, element: <ProjectList />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/crm/project/add`, element: <AddProject />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/crm/project/edit/:id`, element: <UpdateProject />, permissions: []  },
  // Education
  { path: `${process.env.PUBLIC_URL}/education/document/list`, element: <DocumentList />, permissions: []  },
  // Experiments
  { path: `${process.env.PUBLIC_URL}/emission/work/list`, element: <WorkGroupList />, permissions: [] },
  { path: `${process.env.PUBLIC_URL}/emission/work/add`, element: <AddWorkGroup />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/emission/work/edit/:id`, element: <UpdateWorkGroup />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/emission/work/detail/:id`, element: <WorkDetail />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/emission/work-order/list`, element: <WorkOrderList />, 
     permissions: ['view_work_orders'],
  },
  { path: `${process.env.PUBLIC_URL}/experiments/laboratory/list`, element: <LaboratoryList />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/laboratory/add`, element: <AddLaboratory />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/laboratory/edit/:id`, element: <UpdateLaboratory />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/analysis-methods/list`, element: <AnalysisMethodList />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/analysis-methods/add`, element: <AddAnalysisMethod />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/analysis-methods/edit/:id`, element: <UpdateAnalysisMethod />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/experiment/list`, element: <ExperimentList />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/experiment/add`, element: <AddExperiment />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/experiment/edit/:id`, element: <UpdateExperiment />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/table/list`, element: <TableList />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/table/add`, element: <AddTable />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/experiments/table/edit/:id`, element: <UpdateTable />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/offers/offer/add/:id`, element: <AddOffer />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/offers/offer/edit/:id`, element: <UpdateOffer />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/offers/offer/list`, element: <OfferList />, permissions: []  },
  { path: `${process.env.PUBLIC_URL}/plannings/planning/add`, element: <AddPlanning />, permissions: []  },
  // Others
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/typography`, element: <Typography /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/avatars`, element: <Avatars /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/helper-classes`, element: <HelperClasses /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/scrollable`, element: <Scrollable /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/tree`, element: <TreeView /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/toasts`, element: <Toasts /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/tabs`, element: <Tabs /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/lists`, element: <Lists /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/dropdown`, element: <Dropdown /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/accordion`, element: <Accordion /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/alert`, element: <Alert /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/popover`, element: <Popover /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/tooltip`, element: <Tooltip /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/modal`, element: <Modal /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/grid`, element: <Grid /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/tag-pills`, element: <TagAndPills /> },
  // { path: `${process.env.PUBLIC_URL}/components/ui-kits/progress-bar`, element: <Progress /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/product-page`, element: <ProductPage /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/product-list`, element: <ProductList /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/add-products`, element: <AddProduct /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/product`, element: <Product /> },
  // { path: `${process.env.PUBLIC_URL}/applications/project/project-list`, element: <ProjectList /> },
  // { path: `${process.env.PUBLIC_URL}/applications/project/create-project`, element: <ProjectCreate /> },
  // { path: `${process.env.PUBLIC_URL}/applications/file-manager`, element: <FileManager /> },
  // { path: `${process.env.PUBLIC_URL}/widgets/general-widget`, element: <General /> },
  // { path: `${process.env.PUBLIC_URL}/widgets/chart-widget`, element: <Chart /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, element: <Ecommerce /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/project`, element: <Project /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/editors/ck-editor`, element: <CkEditor /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/editors/mde-editor`, element: <MdeEditor /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/editors/ace-code-editor`, element: <AceCodeEditor /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/support-ticket`, element: <SupportTicket /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/timeline`, element: <Timeline /> },
  // { path: `${process.env.PUBLIC_URL}/components/icons/flag-icon`, element: <FlagIcons /> },
  // { path: `${process.env.PUBLIC_URL}/components/icons/font-awesome`, element: <FontAwesomeIcon /> },
  // { path: `${process.env.PUBLIC_URL}/components/icons/ico-icon`, element: <IcoIcon /> },
  // { path: `${process.env.PUBLIC_URL}/components/icons/themify-icon`, element: <ThemifyIcon /> },
  // { path: `${process.env.PUBLIC_URL}/components/icons/feather-icon`, element: <FeatherIcons /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/grid-gallery`, element: <GalleryGrid /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/grid-gallery-description`, element: <GalleryDescription /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/masonry-gallery`, element: <MasonryGallery /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/masonry-gallery-description`, element: <MasonryGalleryDescription /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/gallery-hover`, element: <ImageHoverEffects /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/blog/blog-detail`, element: <BlogDetails /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/blog/blog-single`, element: <BlogSingle /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/blog/add-post`, element: <AddPost /> },
  // { path: `${process.env.PUBLIC_URL}/table/data-tables/basic-init`, element: <BasicInit /> },
  // { path: `${process.env.PUBLIC_URL}/table/data-tables/advance-init`, element: <AdvanceInit /> },
  // { path: `${process.env.PUBLIC_URL}/table/data-tables/api`, element: <APIDataTables /> },
  // { path: `${process.env.PUBLIC_URL}/table/data-tables/data-sources`, element: <DataSource /> },
  // { path: `${process.env.PUBLIC_URL}/pages/sample-page`, element: <SamplePage /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-layout/form-wizard-2`, element: <FormWizard2 /> },
  // { path: `${process.env.PUBLIC_URL}/table/reactstrap-tables/basic-table`, element: <BasicTables /> },
  // { path: `${process.env.PUBLIC_URL}/table/reactstrap-tables/table-components`, element: <TableComponents /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-layout/form-wizard-1`, element: <FormWizard1 /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-layout/two-factor`, element: <TwoFactor /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/image-cropper`, element: <ImageCropper /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/basic-card`, element: <BasicCard /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/creative-card`, element: <CreativeCard /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/pagination`, element: <Paginations /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/breadcrumb`, element: <Breadcrumb /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/range-slider`, element: <RangeSlider /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/sweet-alert-2`, element: <SweetAlert /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/owl-carousel`, element: <OwlCarousel /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/ribbons`, element: <Ribbons /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/rating`, element: <Rating /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/dropzone`, element: <Dropzone /> },
  // { path: `${process.env.PUBLIC_URL}/components/bonus-ui/tour`, element: <Tour /> },
  // { path: `${process.env.PUBLIC_URL}/applications/chat/chat-private`, element: <PrivateChat /> },
  // { path: `${process.env.PUBLIC_URL}/applications/chat/chat-group`, element: <GroupChat /> },
  // { path: `${process.env.PUBLIC_URL}/applications/users/user-profile`, element: <UserProfile /> },
  // { path: `${process.env.PUBLIC_URL}/applications/users/edit-profile`, element: <EditProfile /> },
  // { path: `${process.env.PUBLIC_URL}/applications/users/user-cards`, element: <UserCards /> },
  // { path: `${process.env.PUBLIC_URL}/applications/social-app`, element: <SocialApp /> },
  // { path: `${process.env.PUBLIC_URL}/applications/search`, element: <SearchResult /> },
  // { path: `${process.env.PUBLIC_URL}/applications/task`, element: <Tasks /> },
  // { path: `${process.env.PUBLIC_URL}/applications/calendar-basic`, element: <Calendar /> },
  // { path: `${process.env.PUBLIC_URL}/applications/to-do`, element: <ToDo /> },
  // { path: `${process.env.PUBLIC_URL}/applications/bookmark`, element: <Bookmarks /> },
  // { path: `${process.env.PUBLIC_URL}/applications/contacts`, element: <Contacts /> },
  // { path: `${process.env.PUBLIC_URL}/applications/email/email-app`, element: <EmailApplication /> },
  // { path: `${process.env.PUBLIC_URL}/applications/email/email-compose`, element: <EmailCompose /> },
  // { path: `${process.env.PUBLIC_URL}/applications/email/letter-box`, element: <LetterBox /> },
  // { path: `${process.env.PUBLIC_URL}/invoice/invoice-1`, element: <Invoice1 /> },
  // { path: `${process.env.PUBLIC_URL}/invoice/invoice-2`, element: <Invoice2 /> },
  // { path: `${process.env.PUBLIC_URL}/invoice/invoice-3`, element: <Invoice3 /> },
  // { path: `${process.env.PUBLIC_URL}/invoice/invoice-4`, element: <Invoice4 /> },
  // { path: `${process.env.PUBLIC_URL}/invoice/invoice-5`, element: <Invoice5 /> },
  // { path: `${process.env.PUBLIC_URL}/invoice/invoice-6`, element: <Invoice6 /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/cart`, element: <Cart /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/wishlist`, element: <Wishlist /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/checkout`, element: <Checkout /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/pricing`, element: <Pricing /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/payment-details`, element: <PaymentDetails /> },
  // { path: `${process.env.PUBLIC_URL}/applications/ecommerce/order-history`, element: <OrderHistory /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/faq`, element: <Faq /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/knowledgebase`, element: <Knowledgebase /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/learning/learning-list-view`, element: <LearningList /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/learning/course-details`, element: <DetailedCourse /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/job-search/job-cards-view`, element: <CardsView /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/job-search/job-list-view`, element: <ListView /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/job-search/job-details`, element: <JobDetails /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/job-search/job-apply`, element: <ApplyJobs /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/maps/google-map`, element: <GoogleMaps /> },
  // { path: `${process.env.PUBLIC_URL}/miscellaneous/maps/leaflet-map`, element: <LeafletMap /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-controls/input-mask`, element: <InputMask /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-controls/mega-options`, element: <MegaOptions /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-widgets/datepicker`, element: <Datepicker /> },
  // { path: `${process.env.PUBLIC_URL}/components/charts/chart-google`, element: <GoogleChart /> },
  // { path: `${process.env.PUBLIC_URL}/components/charts/chartjs`, element: <ChartJsChart /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-controls/form-validation`, element: <FormValidation /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-controls/input-group`, element: <InputGroups /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-widgets/touchspin`, element: <Touchspin /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-widgets/switch`, element: <Switch /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-widgets/typeahead`, element: <Typeahead /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-widgets/clipboard`, element: <Clipboard /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-controls/base-input`, element: <BaseInputs /> },
  // { path: `${process.env.PUBLIC_URL}/forms/form-controls/radio-checkbox-control`, element: <CheckboxAndRadio /> },
  // { path: `${process.env.PUBLIC_URL}/components/icons/whether-icon`, element: <WhetherIcon /> },
  // { path: `${process.env.PUBLIC_URL}/components/buttons/default`, element: <DefaultStyle /> },
  // { path: `${process.env.PUBLIC_URL}/components/buttons/button-group`, element: <ButtonGroup /> },
  // { path: `${process.env.PUBLIC_URL}/components/charts/chart-apex`, element: <ApexChart /> },

]