import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";

// Constants
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getVehicles = async (vehicleListFilter: ListFilterType, filterObj: any) => {
  const filterParams = {
    licencePlate: filterObj?.licencePlate,
    "brand.name": filterObj?.brand,
    model: filterObj?.model,
    year: filterObj?.year,
    fuelType: filterObj?.fuelType,
    gearType: filterObj?.gearType
  };
  return request.get(
    `${Paths.debit}/vehicle/find-all/paginated`,
    {
      page: vehicleListFilter.page,
      size: vehicleListFilter.size,
      ...(vehicleListFilter?.sort && {sort: vehicleListFilter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getVehiclesAll = async () => {
  return request.get(
    `${Paths.debit}/vehicle/find-all/paginated`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getVehicleById = async (id: any) => {
  return request.get(
    `${Paths.debit}/vehicle/find-by-id/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteVehicle = async (vehicleId: string) => {
  return request.delete(
    `${Paths.debit}/vehicle/toggle-delete/${vehicleId}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateVehicle = async (vehicle: any) => {
  return request.put(`${Paths.debit}/vehicle/update`, vehicle, {
    headers: Headers.headers,
  });
};

export const addVehicle = async (vehicle: any) => {
  return request.post(`${Paths.debit}/vehicle/create`, vehicle, {}, {
    headers: Headers.headers,
  });
};