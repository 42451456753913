import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";

// Constants
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getDevices = async (deviceListFilter: ListFilterType, filterObj: any) => {
  const filterParams = {
    "brand.name": filterObj?.brand,
    name: filterObj?.name,
    serialNumber: filterObj?.serialNumber,
    calibration: filterObj?.calibration,
    shouldIntermediateCalibration: filterObj?.shouldIntermediateCalibration
  };
  return request.get(
    `${Paths.debit}/device/find-all/paginated`,
    {
      page: deviceListFilter.page,
      size: deviceListFilter.size,
      ...(deviceListFilter?.sort && {sort: deviceListFilter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getDevicesAll = async () => {
  return request.get(
    `${Paths.debit}/device/find-all/paginated`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getDeviceById = async (id: any) => {
  return request.get(
    `${Paths.debit}/device/find-by-id/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteDevice = async (deviceId: string) => {
  return request.delete(
    `${Paths.debit}/device/toggle-delete/${deviceId}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateDevice = async (device: any) => {
  return request.put(`${Paths.debit}/device/update`, device, {
    headers: Headers.headers,
  });
};

export const addDevice = async (device: any) => {
  return request.post(`${Paths.debit}/device/create`, device, {}, {
    headers: Headers.headers,
  });
};

