import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { dateFormats } from "Utils/Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DatePicker, Switch } from "antd";

// Services
import * as brandService from "Services/DebitServices/Brand";
import * as vehicleClassService from "Services/DebitServices/VehicleClass";
import * as vehicleService from "Services/DebitServices/Vehicle";

// Enums
import { FuelTypes } from "Enums/Debit/Fuel";
import { GearTypes } from "Enums/Debit/Gear";
import { VehicleType } from "Types/Debit/VehicleType";

export default function UpdateVehicle() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<any>();

  const { id } = useParams<{ id: string | undefined }>();

  const [vehicleDetails, setVehicleDetails] = useState({} as VehicleType);

  const fuelTypes = FuelTypes;
  const gearTypes = GearTypes;

  const [brandList, setBrandList] = useState([]);
  const [vehicleClassList, setVehicleClassList] = useState([]);
  const [takeBeforeMesure, setTakeBeforeMesure] = useState(false);
  const [trailer, setTrailer] = useState(false);
  const [activeInsuranceDate, setActiveInsuranceDate] = useState("");
  const [activeTrafficInsuranceDate, setActiveTrafficInsuranceDate] =
    useState("");

  const [selectedBrand, setSelectedBrand] = useState(null as any);

  const updateVehicle: SubmitHandler<any> = async (formData) => {
    console.log(formData);
    const updatedData = {
      ...formData,
      id: vehicleDetails?.id,
      brand: {
        ...selectedBrand,
        id: formData.brand ? formData.brand : vehicleDetails?.brand?.id,
      },
      vehicleClass: {
        id: formData.vehicleClass
      },
      takeBeforeMesure: takeBeforeMesure,
      trailer: trailer,
      activeInsuranceDate: activeInsuranceDate
        ? dayjs(activeInsuranceDate).format(dateFormats.D_M_Y_H_m_s_Z)
        : vehicleDetails?.activeInsuranceDate,
      activeTrafficInsuranceDate: activeTrafficInsuranceDate
        ? dayjs(activeTrafficInsuranceDate).format(dateFormats.D_M_Y_H_m_s_Z)
        : vehicleDetails?.activeTrafficInsuranceDate,
      productType: "VEHICLE",
      cargoCapacity: formData.cargoCapacity
        ? Number(formData.cargoCapacity)
        : vehicleDetails?.cargoCapacity,
      consumeRate: formData.consumeRate
        ? Number(formData.consumeRate)
        : vehicleDetails?.consumeRate,
      emptyWeight: formData.emptyWeight
        ? Number(formData.emptyWeight)
        : vehicleDetails?.emptyWeight,
      km: formData.km ? Number(formData.km) : vehicleDetails?.km,
      // maxSpeed: formData.maxSpeed
      //   ? Number(formData.maxSpeed)
      //   : vehicleDetails?.maxSpeed,
      model: formData.model ? formData.model : vehicleDetails?.model,
      modelYear: formData.modelYear
        ? Number(formData.modelYear)
        : vehicleDetails?.modelYear,
      passengerCapacity: formData.passengerCapacity
        ? Number(formData.passengerCapacity)
        : vehicleDetails?.passengerCapacity,
      // wheelCount: formData.wheelCount
      //   ? Number(formData.wheelCount)
      //   : vehicleDetails?.wheelCount,
      licencePlate: formData.licencePlate
        ? formData.licencePlate
        : vehicleDetails?.licencePlate,
      features: formData.features
    };
    console.log(updatedData);
    const { error }: any = await vehicleService.updateVehicle(updatedData);
    navigate(`${process.env.PUBLIC_URL}/debit/vehicle/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
  };

  const getBrands = async () => {
    const { data, error }: any = await brandService.getBrandsAll();
    setBrandList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const getVehicleClass = async () => {
    const { data, error }: any = await vehicleClassService.getVehicleClassAll();
    setVehicleClassList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const getVehileById = async () => {
    const { data, error }: any = await vehicleService.getVehicleById(id);
    setVehicleDetails(data?.body);
    setSelectedBrand(data?.brand);
    setTakeBeforeMesure(data?.body?.takeBeforeMeasure);
    setTrailer(data?.body?.trailer);
    setActiveInsuranceDate(data?.body?.activeInsuranceDate);
    setActiveTrafficInsuranceDate(data?.body?.activeTrafficInsuranceDate);
    setValue("name", data?.body?.name);
    setValue("brand", data?.body?.brand?.id);
    setValue("vehicleClass", data?.body?.vehicleClass?.id);
    setValue("cargoCapacity", data?.body?.cargoCapacity);
    setValue("consumeRate", data?.body?.consumeRate);
    setValue("emptyWeight", data?.body?.emptyWeight);
    setValue("km", data?.body?.km);
    setValue("maxSpeed", data?.body?.maxSpeed);
    setValue("model", data?.body?.model);
    setValue("modelYear", data?.body?.modelYear);
    setValue("gearType", data?.body?.gearType);
    setValue("fuelType", data?.body?.fuelType);
    setValue("passengerCapacity", data?.body?.passengerCapacity);
    setValue("wheelCount", data?.body?.wheelCount);
    setValue("licencePlate", data?.body?.licencePlate);
    setValue("features", data?.body?.features);
    if (error) toast.error(error?.meta.errorDescription);
  };

  useEffect(() => {
    getBrands();
    getVehicleClass();
  }, []);

  useEffect(() => {
    getVehileById();
  }, [id]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.EditVehicle")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.VehiclePageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/vehicle/list`}
        title={t("DebitPages.EditVehicle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(updateVehicle)}
                >
                  <Row>
                    {/* <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.name") + " *"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={vehicleDetails?.name}
                          placeholder={`${t("Common.name")}`}
                          {...register("name", {
                            validate: (value) =>
                              value !== "" || getValues("name") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t("FormValidations.DebitPages.NameRequired")}
                        </span>
                      </FormGroup>
                    </Col> */}
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.brand") + " *"}</Label>
                        <select
                          className="form-control"
                          defaultValue={vehicleDetails?.brand?.id}
                          {...register("brand", {
                            validate: (value) =>
                              value !== "" || getValues("brand") !== "",
                          })}
                          onChange={(event) => {
                            register("brand").onChange(event);
                            const selectedId = event.target.value;
                            const selectedBrandObj = brandList.find(
                              (item: any) => item.id === selectedId
                            );
                            setSelectedBrand(selectedBrandObj);
                            setValue("brand", selectedId);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {brandList.map((item: any, ind) => (
                            <option
                              key={ind}
                              value={item.id}
                              selected={item.id === vehicleDetails?.brand?.id}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.brand &&
                            t("FormValidations.DebitPages.BrandRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.vehicleClass") + " *"}</Label>
                        <select
                          className="form-control"
                          defaultValue={vehicleDetails?.vehicleClass?.id}
                          {...register("vehicleClass", {
                            validate: (value) =>
                              value !== "" || getValues("vehicleClass") !== "",
                          })}
                          onChange={(e) => {
                            register("vehicleClass").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {vehicleClassList.map((item: any, ind) => (
                            <option
                              key={ind}
                              value={item.id}
                              selected={
                                item.id === vehicleDetails?.vehicleClass?.id
                              }
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.vehicleClass &&
                            t(
                              "FormValidations.DebitPages.VehicleClassRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.model") + " *"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={vehicleDetails?.model}
                          placeholder={`${t("Common.model")} *`}
                          {...register("model", {
                            validate: (value) =>
                              value !== "" || getValues("model") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.model &&
                            t("FormValidations.DebitPages.ModelRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.modelYear") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={vehicleDetails?.modelYear}
                          placeholder={`${t("Common.modelYear")} *`}
                          {...register("modelYear", {
                            validate: (value) =>
                              value !== "" || getValues("modelYear") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.modelYear &&
                            t("FormValidations.DebitPages.ModelYearRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.gearType") + " *"}</Label>
                        <select
                          className="form-control"
                          defaultValue={vehicleDetails?.gearType}
                          {...register("gearType", {
                            validate: (value) =>
                              value !== "" || getValues("gearType") !== "",
                          })}
                          onChange={(e) => {
                            register("gearType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(gearTypes).map((key) => (
                            <option
                              key={key}
                              value={key}
                              selected={key === vehicleDetails?.gearType}
                            >
                              {t(
                                `Common.${
                                  GearTypes[key as keyof typeof GearTypes]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.gearType &&
                            t("FormValidations.DebitPages.GearTypeRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.fuelType") + " *"}</Label>
                        <select
                          className="form-control"
                          defaultValue={vehicleDetails?.fuelType}
                          {...register("fuelType", {
                            validate: (value) =>
                              value !== "" || getValues("fuelType") !== "",
                          })}
                          onChange={(e) => {
                            register("fuelType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(fuelTypes).map((key) => (
                            <option
                              key={key}
                              value={key}
                              selected={key === vehicleDetails?.fuelType}
                            >
                              {t(
                                `Common.${
                                  FuelTypes[key as keyof typeof FuelTypes]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.fuelType &&
                            t("FormValidations.DebitPages.FuelTypeRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.consumeRate") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={vehicleDetails?.consumeRate}
                          placeholder={`${t("Common.consumeRate")} *`}
                          {...register("consumeRate", {
                            validate: (value) =>
                              value !== "" || getValues("consumeRate") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.consumeRate &&
                            t("FormValidations.DebitPages.ConsumeRateRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.licencePlate") + " *"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={vehicleDetails?.licencePlate}
                          placeholder={`${t("Common.licencePlate")} *`}
                          {...register("licencePlate", {
                            validate: (value) =>
                              value !== "" || getValues("licencePlate") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.licencePlate &&
                            t(
                              "FormValidations.DebitPages.LicencePlateRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{"KM" + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={vehicleDetails?.km}
                          placeholder={`KM *`}
                          {...register("km", {
                            validate: (value) =>
                              value !== "" || getValues("km") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.km &&
                            t("FormValidations.DebitPages.KmRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.emptyWeight") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={vehicleDetails?.emptyWeight}
                          placeholder={`${t("Common.emptyWeight")} *`}
                          {...register("emptyWeight", {
                            validate: (value) =>
                              value !== "" || getValues("emptyWeight") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.emptyWeight &&
                            t("FormValidations.DebitPages.EmptyWeightRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.cargoCapacity")}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={vehicleDetails?.cargoCapacity}
                          placeholder={`${t("Common.cargoCapacity")}`}
                          {...register("cargoCapacity", {
                            validate: (value) =>
                              value !== "" || getValues("cargoCapacity") !== "",
                          })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.maxSpeed") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={vehicleDetails?.maxSpeed}
                          placeholder={`${t("Common.maxSpeed")} *`}
                          {...register("maxSpeed", {
                            validate: (value) =>
                              value !== "" || getValues("maxSpeed") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.maxSpeed &&
                            t("FormValidations.DebitPages.MaxSpeedRequired")}
                        </span>
                      </FormGroup>
                    </Col> */}
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.passengerCapacity") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={vehicleDetails?.passengerCapacity}
                          placeholder={`${t("Common.passengerCapacity")} *`}
                          {...register("passengerCapacity", {
                            validate: (value) =>
                              value !== "" ||
                              getValues("passengerCapacity") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.passengerCapacity &&
                            t(
                              "FormValidations.DebitPages.PassengerCapacityRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    {/* <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.wheelCount") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={vehicleDetails?.wheelCount}
                          placeholder={`${t("Common.wheelCount")} *`}
                          {...register("wheelCount", {
                            validate: (value) =>
                              value !== "" || getValues("wheelCount") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.wheelCount &&
                            t("FormValidations.DebitPages.WheelCountRequired")}
                        </span>
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row className="mb-4">
                    <Col xs={12} md={4}>
                      <FormGroup className="d-flex align-items-center h-100">
                        <Label>{t("Common.takeBeforeMesure")}</Label>
                        <div className="mx-3">
                          <Switch
                            value={vehicleDetails?.takeBeforeMeasure}
                            checked={takeBeforeMesure}
                            onChange={(e: any) => {
                              setTakeBeforeMesure(e);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup className="d-flex align-items-center h-100">
                        <Label>{t("Common.trailer")}</Label>
                        <div className="mx-3">
                          <Switch
                            value={vehicleDetails?.trailer}
                            checked={trailer}
                            onChange={(e: any) => {
                              setTrailer(e);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.activeInsuranceDate")}</Label>
                        <DatePicker
                          showTime
                          onChange={(e: any) => {
                            setActiveInsuranceDate(e);
                          }}
                          defaultValue={dayjs(
                            vehicleDetails?.activeInsuranceDate
                          )}
                          value={dayjs(activeInsuranceDate)}
                          format={dateFormats.Y_M_D_H_m_s}
                          className={`form-control`}
                          required
                        />
                        <span className="form-error-text">
                          {errors.activeInsuranceDate &&
                            t(
                              "FormValidations.DebitPages.ActiveInsuranceDateRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.activeTrafficInsuranceDate")}</Label>
                        <DatePicker
                          showTime
                          onChange={(e: any) => {
                            setActiveTrafficInsuranceDate(e);
                          }}
                          defaultValue={dayjs(
                            vehicleDetails?.activeTrafficInsuranceDate
                          )}
                          value={dayjs(activeInsuranceDate)}
                          format={dateFormats.Y_M_D_H_m_s}
                          className={`form-control`}
                          required
                        />
                        <span className="form-error-text">
                          {errors.activeTrafficInsuranceDate &&
                            t(
                              "FormValidations.DebitPages.ActiveTrafficInsuranceDateRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.features")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          defaultValue={vehicleDetails?.features}
                          {...register("features", { required: false })}
                        ></textarea>
                        <span className="form-error-text">
                          {errors.features &&
                            t("FormValidations.DebitPages.FeaturesRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.update")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/vehicle/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
