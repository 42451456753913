import request from "Services/files/Request";
import * as Paths from "Services/files/Paths";
import * as Headers from "Services/files/Headers";
import {ListFilterType} from "Types/Common/ListFilterType";

export const addWorkGroup = async (workGroup: any) => {
  return request.post(`${Paths.emission}/work-group`, workGroup, {}, {
    headers: Headers.headers,
  });
};

export const updateWorkGroup = async (workGroup: any) => {
  return request.put(`${Paths.emission}/work-group`, workGroup, {
    headers: Headers.headers,
  });
};

export const getWorkGroup = async (id: any) => {
  return request.get(`${Paths.emission}/work-group/${id}`, {}, {
    headers: Headers.headers,
  });
};

export const  getWorkGroups = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
  };
  return request.get(
    `${Paths.emission}/work-group/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
}