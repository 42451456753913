import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Collapse, Container, Label, Row,} from "reactstrap";
import {DatePicker, Form as AntForm, Select as AntSelect} from "antd";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Btn, LI, P, UL} from "AbstractElements";
import {toast} from "react-toastify";
import {SubmitHandler, useForm} from "react-hook-form";
import DataTable, {TableColumn} from "react-data-table-component";
import {dateFormats, defaultPageAndSizeForAll, Href} from "Utils/Constants";
import {Plus} from "react-feather";
import {ListFilterType} from "Types/Common/ListFilterType";

// Services
import * as contactService from "Services/CrmServices/Contact";
import * as laboratoryService from "Services/MetriksServices/Laboratory";
import * as userService from "Services/UserService";
import * as workService from "Services/MetriksServices/Work";

import {OfferLogoType} from "Enums/Metriks/OfferLogoType";
import dayjs from "dayjs";
import {Status} from "Enums/Common/Status";

const offerLogoTypes = OfferLogoType;

export default function WorkLaboratories({ work }: any) {
  const { t } = useTranslation();
  const [form] = AntForm.useForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tableData, setTableData] = useState([] as any[]);
  const [discoveryDate, setDiscoveryDate] = useState("");
  const [discoveryEndDate, setDiscoveryEndDate] = useState("");
  const [discoveryStaff, setDiscoveryStaff] = useState([]);
  const [discoveryStaffList, setDiscoveryStaffList] = useState([]);
  const [laboratories, setLaboratories] = useState([]);
  const [exploration, setExploration] = useState(false);

  const [tableFilter, setTableFilter] = useState(
    defaultPageAndSizeForAll as ListFilterType
  );
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const getWorks = async () => {
    const { data, error }: any = await workService.getWorks(tableFilter, {workGroupId: work?.id});
    setTableData(data.body?.content);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  }

  useEffect(() => {
    getWorks();
  }, []);
  
  const getLaboratories = async () => {
    const { data, error }: any = await laboratoryService.getLaboratoriesAll();
    const newData = data?.body?.content.map((lab : any) => {
      return {label: lab?.name, value: lab?.id + '_' + lab?.exploration};
    })
    setLaboratories(newData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  }

  useEffect(() => {
    getLaboratories();
  }, []);

  const getStaffList = async () => {
    const { data, error }: any = await userService.getUsersAll();
    const newData = data?.body?.content.map((staff : any) => {
      return {label: staff.firstName + ' ' + staff.lastName, value: staff.id};
    })
    setDiscoveryStaffList(newData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getStaffList();
  }, []);

  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const handleCollapseCancel = () => {
    handleFilterToggle();
  };

  const handleLaboratoryChange = (e : string) => {
    if(e?.split('_')[1] === 'true') {
      setExploration(true);
    } else {
      setExploration(false);
      clearDiscoveryFormItems();
    }
  };
  
  const clearDiscoveryFormItems = () => {
    setDiscoveryDate("");
    setDiscoveryEndDate("");
    setDiscoveryStaff([]);
    form.resetFields(["discoveryStaff", "discoveryStartDate", "discoveryEndDate"])
  }

  const addWork: SubmitHandler<any> = async (formData) => {
    const newData: any = {
      workGroupId: work?.id,
      laboratory: {id: formData?.laboratory?.split("_")[0]},
      logoType: formData?.logo,
      discoveryStartDate: discoveryDate !== "" ? dayjs(discoveryDate).format(dateFormats.D_M_Y_H_m_s_Z) : "",
      discoveryEndDate: discoveryEndDate !== "" ? dayjs(discoveryEndDate).format(dateFormats.D_M_Y_H_m_s_Z) : "",
      workOrderAssigns: discoveryStaff.length !== 0 ? [{
        authorizationType: "USER_IDS",
        authorizedIds: discoveryStaff
      }] : null
    };
    const { data, error }: any = await workService.addWork(newData);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      await getWorks();
      form.resetFields();
    }
  };

  const deleteWork = async (id: string) => {
    const { error }: any = await contactService.deleteContact(id);
    toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      setTableData(tableData.filter((item: any) => item.id !== id));
    }
  };

  const tableColumns: TableColumn<any>[] = [
    {
      name: t("ExperimentPages.WorkPages.WorkNo"),
      selector: (row) => row?.code,
      sortable: true,
      cell: (row) => <P className="f-light">{row?.code ? row?.code : "-"}</P>,
    },
    {
      name: t("ExperimentPages.LaboratoryPages.Laboratory"),
      selector: (row) => row?.laboratory?.name,
      sortable: true,
      cell: (row) => <P className="f-light">{row?.laboratory?.name ? row?.laboratory?.name : "-"}</P>,
    },
    {
      name: t("Common.code"),
      selector: (row) => row?.laboratory?.code,
      sortable: true,
      cell: (row) => <P className="f-light">{row?.laboratory?.code ? row?.laboratory?.code : "-"}</P>,
    },
    {
      name: t("Common.status"),
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <P className="f-light">
        {row.status
          ? t(`Common.${Status[row.status as keyof typeof Status]}`)
          : "-"}
      </P>,
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="other me-1">
              <Link to={`/emission/work/detail/${row?.id}`}>
                <i className="fa fa-eye"></i>
              </Link>
            </LI>
            <LI className="delete">
              <Link onClick={() => deleteWork(row?.id)} to={""}>
                <i className="icon-trash" />
              </Link>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  return (
    <div>
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="light-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>{t("ExperimentPages.WorkPages.CreateWork")}</label>
                    <a href={Href} onClick={handleFilterToggle}>
                      {isFilterOpen ? (
                        <i className="icon-close filter-close" />
                      ) : (
                        <Plus />
                      )}
                    </a>
                  </div>
                </div>
                <Collapse className={isFilterOpen ? "show" : ""}>
                  <Card className="list-product-body">
                    <CardBody>
                      <AntForm
                        form={form}
                        className="theme-form"
                        onFinish={addWork}
                        autoComplete="off"
                        initialValues={{
                          name: "",
                          title: "",
                          phone: "",
                          email: ""
                        }} 
                      >
                        <Row>
                          <Col>
                            <Label>{t("ExperimentPages.LaboratoryPages.Laboratory") + " *"}</Label>
                            <AntForm.Item
                              name="laboratory"
                              validateTrigger={["onChange", "onBlur"]}
                              rules={ [
                                {
                                  required: true,
                                  message: t(
                                    "FormValidations.ExperimentPages.WorkPages.LaboratoryRequired"
                                  ),
                                },
                              ]}
                            >
                              <AntSelect
                                size="large"
                                placeholder={`${t("ExperimentPages.LaboratoryPages.Laboratory")} *`}
                                onChange={e => {
                                  handleLaboratoryChange(e)
                                }}
                                options={laboratories}
                              ></AntSelect>
                            </AntForm.Item>
                          </Col>
                          <Col>
                            <Label>{t("ExperimentPages.WorkPages.Logo") + " *"}</Label>
                            <AntForm.Item
                              name="logo"
                              validateTrigger={["onChange", "onBlur"]}
                              rules={ [
                                {
                                  required: true,
                                  message: t(
                                    "FormValidations.ExperimentPages.WorkPages.LogoRequired"
                                  ),
                                },
                              ]}
                            >
                              <AntSelect
                                size="large"
                                placeholder={`${t("ExperimentPages.WorkPages.Logo")} *`}
                                options={Object.keys(offerLogoTypes).map((key) => {
                                  return {label: t(
                                      `ExperimentPages.WorkPages.${
                                        OfferLogoType[
                                          key as keyof typeof OfferLogoType
                                          ]
                                      }`
                                    ), value: key}
                                })}
                              ></AntSelect>
                            </AntForm.Item>
                          </Col>
                        </Row>
                        {
                          exploration ?
                            (<Row>
                              <Col>
                                <Label>{t("EmissionPages.DiscoveryStaff") + " *"}</Label>
                                <AntForm.Item
                                  name="discoveryStaff"
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={ [
                                    {
                                      required: true,
                                      message: t(
                                        "FormValidations.EmissionPages.DiscoveryStaffRequired"
                                      ),
                                    },
                                  ]}
                                >
                                  <AntSelect
                                    onChange={e => {
                                      setDiscoveryStaff(e)
                                    }}
                                    size="large"
                                    mode="multiple"
                                    placeholder={`${t("EmissionPages.DiscoveryStaff")} *`}
                                    options={discoveryStaffList}
                                  ></AntSelect>
                                </AntForm.Item>
                              </Col>
                              <Col>
                                <AntForm.Item
                                  name="discoveryStartDate"
                                  validateTrigger={["onChange", "onBlur"]}
                                >
                                  <Label>{t("EmissionPages.DiscoveryDate") + " *"}</Label>
                                  <DatePicker
                                    placeholder={t("EmissionPages.DiscoveryDate") + " *"}
                                    format={dateFormats.Y_M_D}
                                    className={`form-control`}
                                    required
                                    onChange={(e: any) => {
                                      setDiscoveryDate(e);
                                    }}
                                  />
                                </AntForm.Item>
                              </Col>
                              <Col>
                                <AntForm.Item
                                  name="discoveryEndDate"
                                  validateTrigger={["onChange", "onBlur"]}
                                >
                                  <Label>{t("EmissionPages.DiscoveryEndDate") + " *"}</Label>
                                  <DatePicker
                                    placeholder={t("EmissionPages.DiscoveryEndDate") + " *"}
                                    format={dateFormats.Y_M_D}
                                    className={`form-control`}
                                    required
                                    onChange={(e: any) => {
                                      setDiscoveryEndDate(e);
                                    }}
                                  />
                                </AntForm.Item>
                              </Col>
                            </Row>) : null
                        }
                        <Row>
                          <Col>
                            <div className="text-end">
                              <Btn color="outline-success" className="me-3">
                                {t("Common.Save")}
                              </Btn>
                              <Btn
                                color="outline-danger"
                                className="btn"
                                onClick={handleCollapseCancel}
                              >
                                {t("Common.cancel")}
                              </Btn>
                            </div>
                          </Col>
                        </Row>
                      </AntForm>
                    </CardBody>
                  </Card>
                </Collapse>
                <DataTable
                  data={tableData}
                  columns={tableColumns}
                  pagination={false}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
