import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { defaultPageAndSize, Href } from "Utils/Constants";
import { Filter } from "react-feather";
import { Link } from "react-router-dom";
import { Btn, LI, P, UL } from "../../../AbstractElements";
import DataTable, { TableColumn } from "react-data-table-component";
import { Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FilterInput from "Components/Filters/FilterInput";
import FilterSelect from "Components/Filters/FilterSelect";

// Services
import * as analysisMethodsService from "Services/MetriksServices/AnalysisMethod";
import * as laboratoryService from "Services/MetriksServices/Laboratory";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { Laboratory } from "Types/Emission/Laboratory";

export default function AnalysisMethodList() {
  const { t } = useTranslation();
  const [laboratories, setLaboratories] = useState([] as Laboratory[]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const [analysisMethodListData, setAnalysisMethodListData] = useState(
    [] as any[]
  );
  const [filter, setFilter] = useState(defaultPageAndSize as ListFilterType);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const columns: TableColumn<any>[] = [
    {
      name: t("ExperimentPages.AnalysisMethodPages.StandardNo"),
      sortField: "standardNo",
      selector: (row) => row.standardNo,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.standardNo}</P>,
    },
    {
      name: t("Common.name"),
      sortField: "name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.name ? row.name : "-"}</P>,
    },
    {
      name: t("ExperimentPages.LaboratoryPages.Laboratory"),
      sortField: "laboratory",
      selector: (row) => row?.laboratory?.name,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row?.laboratory?.name}</P>,
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            {
              <LI className="edit">
                <Tooltip placement="bottom" title={t("Common.Edit")}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/experiments/analysis-methods/edit/${row.id}`}
                  >
                    <i className="icon-pencil-alt" />
                  </Link>
                </Tooltip>
              </LI>
            }
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteAnalysisMethod(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [loadingTable, setLoadingTable] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getAnalysisMethods = async (filter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any =
      await analysisMethodsService.getAnalysisMethods(filter, filterObj);
    setLoadingTable(false);
    setAnalysisMethodListData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(false);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const handlePageChange = (page: number) => {
    setFilter({
      ...filter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!analysisMethodListData.length) return;
    setFilter({
      ...filter,
      size: newRowsPerPage,
    });
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getAnalysisMethods({
      ...filter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteAnalysisMethod = async (id: string) => {
    const { error }: any = await analysisMethodsService.deleteAnalysisMethod(
      id
    );
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
      await getAnalysisMethods(filter);
    }
  };

  const getLaboratories = async () => {
    const { data, error }: any = await laboratoryService.getLaboratoriesAll();
    setLaboratories(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  useEffect(() => {
    getLaboratories();
  }, []);

  useEffect(() => {
    getAnalysisMethods(filter);
  }, [filter]);

  // Filter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getAnalysisMethods(filter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getAnalysisMethods(filter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t(
          "ExperimentPages.AnalysisMethodPages.AnalysisMethodPageTitle"
        )}
        parent={t("ExperimentPages.LaboratoryPages.Metriks")}
        title={t("ExperimentPages.AnalysisMethodPages.AnalysisMethodPageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <a href={Href} onClick={handleFilterToggle}>
                      <div className="light-box">
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </div>
                    </a>
                    <Link
                      className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/experiments/analysis-methods/add`}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t(
                        "ExperimentPages.AnalysisMethodPages.AddAnalysisMethod"
                      )}
                    </Link>
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-4 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">{t("Common.name")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"name"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t(
                                "ExperimentPages.AnalysisMethodPages.StandardNo"
                              )}
                            </Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"standardNo"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("ExperimentPages.LaboratoryPages.Laboratory")}
                            </Label>
                            <FilterSelect
                              inputName={"laboratory"}
                              setFilterObjFun={setFilterObj}
                              isStatus={false}
                              options={laboratories.map((lab) => {
                                return {
                                  text: lab.name,
                                  value: lab.id,
                                };
                              })}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={analysisMethodListData}
                    columns={columns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={filter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText"),
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
