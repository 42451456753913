import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { Bank } from "Types/Crm/Bank";

export const deleteBank = async (id: string) => {
  return request.delete(
    `${Paths.crm}/bank/toggle-delete/${id}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const addBank = async (bank: Bank) => {
  return request.post(
    `${Paths.crm}/bank/create`, bank, {},
    {
      headers: Headers.headers,
    }
  );
};