import * as Paths from './files/Paths';
import * as Headers from './files/Headers';
import axios from 'axios';

// Types
import { Login } from 'Types/User/UserType';

export const login = (loginData: Login) => {
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}${Paths.login}/authenticate`, loginData, 
    { headers: Headers.loginHeaders }
  );
};

export const logOut = () => {
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}${Paths.logout}/logout`, 
    { headers: Headers.headers }
  );
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("prtlb-refreshToken");
  const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${Paths.login}/refresh-token`, { token: refreshToken }, 
    { headers: Headers.loginHeaders }
  );
  const { accessToken } = response.data;
  localStorage.setItem("prtlb-token", accessToken);
  return accessToken;
};