import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import {
  DatePicker,
  Form as AntForm,
  Select as AntSelect,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Btn, LI, P, UL } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import CommonModal from "Utils/CommonComponents/UiKitsCommon/CommonModal";
import DataTable, { TableColumn } from "react-data-table-component";
import { dateFormats } from "Utils/Constants";

// Services
import * as companyService from "Services/CrmServices/Company";
import * as workGroupService from "Services/MetriksServices/WorkGroup";
import WorkLaboratories from "Pages/Metriks/Works/Work/Components/WorkLaboratories";
import * as userService from "Services/UserService";
import * as vehicleService from "Services/DebitServices/Vehicle";
import * as deviceService from "Services/DebitServices/Device";

// Types
import { ProductTypes } from "Enums/Debit/Product";
import { Company } from "Types/Crm/Company";
import _ from "lodash";
const productTypes = ProductTypes;

export default function AddPlanning() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [savedData, setSavedData] = useState<any>(null);
  const [companyList, setCompanyList] = useState([] as Company[]);

  const [discoveryDate, setDiscoveryDate] = useState("");
  const [discoveryEndDate, setDiscoveryEndDate] = useState("");
  const [staffList, setStaffList] = useState<any>([]);
  const [selectedStaff, setSelectedStaff] = useState([] as any);
  const [selectedTableStaffInfo, setSelectedTableStaffInfo] = useState(
    {} as any
  );

  const [selectedType, setSelectedType] = useState<any>("");
  const [productList, setProductList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null);
  const [selectedDevices, setSelectedDevices] = useState<any>([] as any[]);

  const [form] = AntForm.useForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [addDebitModal, setAddDebitModal] = useState<boolean>(false);
  const toggleAddDebitModal = () => {
    setAddDebitModal(!addDebitModal);
    setSelectedType("");
  };
  const addDebitModalData = {
    isOpen: addDebitModal,
    header: true,
    toggler: toggleAddDebitModal,
    title: t("PlanningPages.AddDebit"),
    size: "lg",
    bodyClass: "dark-modal",
    center: true,
  };

  const getStaffList = async () => {
    const { data, error }: any = await userService.getUsersAll();
    const newData = data?.body?.content.map((staff: any) => {
      return {
        label: staff.firstName + " " + staff.lastName,
        value: staff.id,
        data: {
          id: staff.id,
          nameSurname: staff.firstName + " " + staff.lastName,
          phoneNumber: staff.phoneNumber,
          vehicle: null,
          devices: [],
        },
      };
    });
    setStaffList(newData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getStaffList();
  }, []);

  const addWorkGroup: SubmitHandler<any> = async (formData) => {
    const newData: any = {
      ...formData,
    };
    const { data, error }: any = await workGroupService.addWorkGroup(newData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      setSavedData(data?.body);
    }
  };

  const setSelectedStaffObj = (e: any, option: any) => {
    const selectedObjects = option.map((option: any) => option.data);
    setSelectedStaff((prevState: any) => _.uniqBy([...prevState, ...selectedObjects], "id"));
    // setSelectedStaff(selectedObjects);
  };

  const deleteSelectedStaff = (id: any) => {
    setSelectedStaff((prevStaff: any) =>
      prevStaff.filter((staff: any) => staff.id !== id)
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      discoveryStaff: selectedStaff.map((staff: any) => staff.id),
    });
  }, [selectedStaff, form]);

  const tableColumns: TableColumn<any>[] = [
    {
      name: t("PlanningPages.Staff"),
      selector: (row) => row.nameSurname,
      sortable: false,
      cell: (row) => (
        <P className="f-light">{row.nameSurname ? row?.nameSurname : "-"}</P>
      ),
    },
    {
      name: t("PlanningPages.PhoneNumber"),
      selector: (row) => row.phoneNumber,
      sortable: false,
      cell: (row) => (
        <P className="f-light">{row.phoneNumber ? row.phoneNumber : "-"}</P>
      ),
    },
    {
      name: t("PlanningPages.Vehicle"),
      selector: (row) => row.vehicle,
      sortable: false,
      cell: (row) => (
        <P className="f-light">
          {row.vehicle ? (
            <i
              style={{ fontSize: 18 }}
              className="fa fa-check-circle-o text-success"
            ></i>
          ) : (
            <i
              style={{ fontSize: 18 }}
              className="fa fa-times-circle-o text-danger"
            ></i>
          )}
        </P>
      ),
    },
    {
      name: t("PlanningPages.Device"),
      selector: (row) => row.devices,
      sortable: false,
      cell: (row) => (
        <P className="f-light">
          {row.devices && row.devices.length > 0 ? (
            <i
              style={{ fontSize: 18 }}
              className="fa fa-check-circle-o text-success"
            ></i>
          ) : (
            <i
              style={{ fontSize: 18 }}
              className="fa fa-times-circle-o text-danger"
            ></i>
          )}
        </P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="other">
              <Tooltip placement="bottom" title={t("PlanningPages.AddDebit")}>
                <Link
                  onClick={() => {
                    setSelectedTableStaffInfo(row);
                    toggleAddDebitModal();
                  }}
                  to={""}
                >
                  <i className="icon-shift-right"></i>
                </Link>
              </Tooltip>
            </LI>
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteSelectedStaff(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  useEffect(() => {
    console.log("Selected StaffList updated:", selectedStaff);
  }, [selectedStaff]);

  const setSelectedDevicesList = (option: any) => {
    const selectedObjects = option.map((option: any) => option.data);

    const updatedDevices = [...selectedObjects];
    const updatedTableStaffInfo = {
      ...selectedTableStaffInfo,
      devices: _.uniqBy(updatedDevices, "id"),
    };

    setSelectedDevices(updatedDevices);
    setSelectedTableStaffInfo(updatedTableStaffInfo);

    const updatedStaffList = selectedStaff?.map((item: any) =>
      item.id === updatedTableStaffInfo.id
        ? {
            ...item,
            vehicle: selectedVehicle,
            devices: _.uniqBy(updatedDevices, "id"),
          }
        : item
    );

    setSelectedStaff([...updatedStaffList]);
  };

  const addDebitToUser = () => {
    if (selectedType === "VEHICLE") {
      const updatedState = {
        ...selectedTableStaffInfo,
        vehicle: selectedVehicle,
        devices: [],
      };
      setSelectedTableStaffInfo(updatedState);
      const updatedStaffList = selectedStaff?.map((item: any) =>
        item.id === selectedTableStaffInfo.id
          ? {
              ...item,
              vehicle: selectedVehicle,
              devices: _.uniqBy(selectedDevices, "id"),
            }
          : item
      );
      setSelectedStaff([...updatedStaffList]);
    } else if (selectedType === "DEVICE") {
      const updatedState = {
        ...selectedTableStaffInfo,
        vehicle: selectedVehicle,
        devices: _.uniqBy(selectedDevices, "id"),
      };
      setSelectedTableStaffInfo(updatedState);
    }
    toggleAddDebitModal();
    setSelectedType("");
    //setSelectedDevices([]);
    //setSelectedVehicle({});
  };

  const ExpandedComponent = ({ data }: any) => {
    if (!data) {
      return null;
    }

    return (
      <div className="my-2 d-flex">
        {data.vehicle && (
          <div>
            <div className="ps-5 fw-semibold">{t("PlanningPages.Vehicle")}</div>
            <div className="ps-5">
              {data.vehicle.brand?.name} {data.vehicle.model}{" "}
              {data.vehicle.licencePlate}
            </div>
          </div>
        )}
        {data.devices && data.devices.length > 0 && (
          <div>
            <div className="ps-5 fw-semibold">{t("PlanningPages.Devices")}</div>
            {data.devices.map((d: any, ind: any) => (
              <div className="flex flex-column ps-5" key={ind}>
                {ind + 1} - {d?.brand?.name} {d.name}
              </div>
            ))}
          </div>
        )}
        {data.devices.length === 0 && !data.vehicle && (
          <div className="my-2 ps-5">{t("Common.noData")}</div>
        )}
      </div>
    );
  };

  const renderTable = () => {
    return selectedStaff.length > 0 ? (
      <DataTable
        data={selectedStaff}
        columns={tableColumns}
        expandableRowsComponent={ExpandedComponent}
        pagination={false}
        expandableRows={true}
      />
    ) : null;
  };

  const getDevices = async () => {
    const { data, error }: any = await deviceService.getDevicesAll();
    const newData = data?.body?.content.map((dvc: any) => {
      return {
        label: dvc.name,
        value: dvc.id,
        data: dvc,
      };
    });
    setProductList(newData);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const getVehicles = async () => {
    const { data, error }: any = await vehicleService.getVehiclesAll();
    setProductList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  useEffect(() => {
    selectedType === "DEVICE" && getDevices();
    selectedType === "VEHICLE" && getVehicles();
  }, [selectedType]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("PlanningPages.AddPlanning")}
        parent={t("PlanningPages.PlanningPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/emission/work/list`}
        title={t("PlanningPages.AddPlanning")}
      />
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <AntForm
                  className="theme-form"
                  onFinish={addWorkGroup}
                  autoComplete="off"
                  form={form}
                >
                  <Row>
                    <Col>
                      <AntForm.Item
                        name="discoveryStartDate"
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <Label>{t("EmissionPages.DiscoveryDate") + " *"}</Label>
                        <DatePicker
                          placeholder={t("EmissionPages.DiscoveryDate") + " *"}
                          format={dateFormats.Y_M_D}
                          className={`form-control`}
                          required
                          onChange={(e: any) => {
                            setDiscoveryDate(e);
                          }}
                        />
                      </AntForm.Item>
                    </Col>
                    <Col>
                      <AntForm.Item
                        name="discoveryEndDate"
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <Label>
                          {t("EmissionPages.DiscoveryEndDate") + " *"}
                        </Label>
                        <DatePicker
                          placeholder={
                            t("EmissionPages.DiscoveryEndDate") + " *"
                          }
                          format={dateFormats.Y_M_D}
                          className={`form-control`}
                          required
                          onChange={(e: any) => {
                            setDiscoveryEndDate(e);
                          }}
                        />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>{t("EmissionPages.DiscoveryStaff") + " *"}</Label>
                      <AntForm.Item
                        name="discoveryStaff"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.EmissionPages.DiscoveryStaffRequired"
                            ),
                          },
                        ]}
                      >
                        <AntSelect
                          onChange={(e: any, option) => {
                            setSelectedStaffObj(e, option);
                          }}
                          size="large"
                          mode="multiple"
                          placeholder={`${t("EmissionPages.DiscoveryStaff")} *`}
                          options={staffList}
                          value={selectedStaff.map((staff: any) => staff.id)}
                        ></AntSelect>
                      </AntForm.Item>
                    </Col>
                  </Row>
                </AntForm>
                {renderTable()}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CommonModal modalData={addDebitModalData}>
          <Row className="mb-3">
            <Col xs={12}>
              {t("Common.user")}: {selectedTableStaffInfo?.nameSurname}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Label>{t("Common.productType")}</Label>
              <select
                className="form-control mb-2"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                <option value="">{t("Common.PleaseSelect")}</option>
                <option key={"DEVICE"} value={"DEVICE"}>
                  {t(`Common.DeviceType`)}
                </option>
                <option key={"VEHICLE"} value={"VEHICLE"}>
                  {t(`Common.VehicleType`)}
                </option>
              </select>
            </Col>
            {selectedType === "VEHICLE" && (
              <Col xs={12}>
                <Label>{t("Common.product")}</Label>
                <select
                  className="form-control mb-2"
                  onChange={(e: any) => {
                    const selectedIndex = e.target.selectedIndex;
                    const selectedOption = productList[selectedIndex];
                    setSelectedVehicle(selectedOption);
                  }}
                >
                  <option value="">{t("Common.PleaseSelect")}</option>
                  {productList.map((item: any, ind) => (
                    <option key={ind} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            {selectedType === "DEVICE" && (
              <Col xs={12}>
                <Label>{t("Common.product")}</Label>
                <AntSelect
                  onChange={(e: any, option) => {
                    setSelectedDevicesList(option);
                  }}
                  className="w-100"
                  size="large"
                  mode="multiple"
                  placeholder={`${t("Common.PleaseSelect")}`}
                  options={productList}
                ></AntSelect>
              </Col>
            )}
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="text-end">
                <Btn
                  color="success"
                  className="me-3"
                  onClick={() => addDebitToUser()}
                >
                  {t("Common.add")}
                </Btn>
                <Link
                  className="btn btn-danger"
                  to={``}
                  onClick={() => toggleAddDebitModal()}
                >
                  {t("Common.cancel")}
                </Link>
              </div>
            </Col>
          </Row>
        </CommonModal>
      </Container>
    </div>
  );
}
