import React from "react";
import { Col, Row } from "reactstrap";
import { H6 } from "AbstractElements";
import { useTranslation } from "react-i18next";

import { PiIdentificationBadge } from "react-icons/pi";
import { GrUserManager } from "react-icons/gr";
import { MdOutlineDateRange, MdOutlineWorkOutline } from "react-icons/md";
import { LiaFileContractSolid } from "react-icons/lia";
import { GiMeal } from "react-icons/gi";
import { FaRegSquareCheck } from "react-icons/fa6";
import { RootState } from "redux-toolkit/store";
import { useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import dayjs from "dayjs";

// Types
import { ContractTypes } from "Enums/User/ContractType";
import { WorkingTypes } from "Enums/User/WorkingType";
import { EmploymentTypes } from "Enums/User/EmploymentType";
import { MealCardTypes } from "Enums/User/MealCardType";

export default function ProfileCompanyInfos() {
  const { t } = useTranslation();

  const userInfos = useSelector((state: RootState) => state.user);

  return (
    <Col sm={12} xl={12} className="order-sm-2 order-xl-2">
      <Row className="g-3">
        <Col md={4} className="mt-0 mt-sm-3">
          <div className="text-start ttl-xs-mt tour-email">
            <H6 className="tour-mb-space">
              <PiIdentificationBadge className="me-2" />{" "}
              {t("ProfilePages.RegistrationNo")}
            </H6>
            <span>
              {userInfos.registrationNumber
                ? userInfos.registrationNumber
                : "-"}
            </span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <GrUserManager className="me-2" /> {t("Common.Supervisor")}
            </H6>
            <span>{userInfos.supervisor ? userInfos.supervisor : "-"}</span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <i className="fa fa-calendar me-2" />{" "}
              {t("ProfilePages.JobStartDate")}
            </H6>
            <span>
              {userInfos.jobStartDate
                ? dayjs(userInfos.jobStartDate).format("DD-MM-YYYY")
                : "-"}
            </span>{" "}
            {/* what is date format */}
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <MdOutlineWorkOutline className="me-2" />{" "}
              {t("ProfilePages.EmploymentType")}
            </H6>
            <span>
              {userInfos.employmentType
                ? t(
                    `Common.${
                      EmploymentTypes[
                        userInfos.employmentType as keyof typeof EmploymentTypes
                      ]
                    }`
                  )
                : "-"}
            </span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <MdOutlineDateRange className="me-2" />{" "}
              {t("ProfilePages.WorkingType")}
            </H6>
            <span>
              {userInfos.workingType
                ? t(
                    `Common.${
                      WorkingTypes[
                        userInfos.workingType as keyof typeof WorkingTypes
                      ]
                    }`
                  )
                : "-"}
            </span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <LiaFileContractSolid className="me-2" />{" "}
              {t("ProfilePages.ContractType")}
            </H6>
            <span>
              {userInfos.contractType
                ? t(
                    `Common.${
                      ContractTypes[
                        userInfos.contractType as keyof typeof ContractTypes
                      ]
                    }`
                  )
                : "-"}
            </span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <i className="fa fa-calendar me-2" />{" "}
              {t("ProfilePages.ContractStartDate")}
            </H6>
            <span>
              {userInfos.contractStartDate
                ? dayjs(userInfos.contractStartDate).format("DD-MM-YYYY")
                : "-"}
            </span>{" "}
            {/* what is date format */}
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <i className="fa fa-calendar me-2" />{" "}
              {t("ProfilePages.ContractEndDate")}
            </H6>
            <span>
              {userInfos.contractEndDate
                ? dayjs(userInfos.contractEndDate).format("DD-MM-YYYY")
                : "-"}
            </span>{" "}
            {/* what is date format */}
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <GiMeal className="me-2" /> {t("ProfilePages.MealCardType")}
            </H6>
            <span>
              {userInfos.mealCardType
                ? t(
                    `Common.${
                      MealCardTypes[
                        userInfos.mealCardType as keyof typeof MealCardTypes
                      ]
                    }`
                  )
                : "-"}
            </span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              {userInfos.timesheetRequired ? (
                <>
                  <FaRegSquareCheck className="me-2" />{" "}
                  {t("ProfilePages.TimesheetRequired")}
                </>
              ) : (
                <>
                  <FaTimes className="me-2" />{" "}
                  {t("ProfilePages.TimesheetRequired")}
                </>
              )}
            </H6>
          </div>
        </Col>
      </Row>
      {/* <Row className="g-3">
        <Col md={12}>
          <FormGroup className="checkbox checkbox-primary mb-0" check={false}>
            <Input id="timesheetRequired" type="checkbox" defaultChecked />
            <Label htmlFor="timesheetRequired" check>{t('ProfilePages.TimesheetRequired')}</Label>
          </FormGroup>
        </Col>
      </Row> */}
    </Col>
  );
}
