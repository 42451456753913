import request from "Services/files/Request";
import * as Paths from "Services/files/Paths";
import {ListFilterType} from "Types/Common/ListFilterType";
import * as Headers from "Services/files/Headers";

export const getWorkOrders = async (listFilter: ListFilterType, filterObj: any) => {
  return request.get(
    `${Paths.emission}/work-orders/find-all/paginated`,
    {
      page: listFilter.page,
      size: listFilter.size,
      ...(listFilter?.sort && {sort: listFilter?.sort[0]}),
      ...filterObj
    },
    { headers: Headers.headers }
  );
};