import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href, defaultPageAndSize } from "Utils/Constants";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { P, UL, LI, Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { Spin, Tooltip } from "antd";
import FilterInput from "Components/Filters/FilterInput";

// Services
import * as contactService from "Services/CrmServices/Contact";
// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { Contact } from "Types/Crm/Contact";

export default function ContactList() {
  const { t } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };
  const [tableData, setTableData] = useState([] as Contact[]);

  const [tableFilter, setTableFilter] = useState(
    defaultPageAndSize as ListFilterType
  );
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const tableColumns: TableColumn<any>[] = [
    {
      name: t("Common.name"),
      sortField: "name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.name ? row.name : "-"}</P>,
    },
    {
      name: t("Common.Phone"),
      selector: (row) => row.phone,
      sortable: false,
      cell: (row) => <P className="f-light-darker">{row.phone ? row.phone : "-"}</P>,
    },
    {
      name: t("Common.Email"),
      sortField: "email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.email ? row.email : "-"}</P>,
    },
    {
      name: t("Common.Company"),
      sortField: "company",
      selector: (row) => row.company,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.company ? row.company?.name : "-"}</P>
      ),
    },
    {
      name: t("Common.Title"),
      sortField: "title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.title ? row.title : "-"}</P>,
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="edit">
              <Tooltip placement="bottom" title={t("Common.Edit")}>
                <Link
                  to={`${process.env.PUBLIC_URL}/crm/contact/edit/${row.id}`}
                >
                  <i className="icon-pencil-alt" />
                </Link>
              </Tooltip>
            </LI>
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteContact(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getContacts = async (filter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any = await contactService.getContacts(
      filter,
      filterObj
    );
    setLoadingTable(false);
    setTableData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(true);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const handlePageChange = (page: number) => {
    setTableFilter({
      ...tableFilter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!tableData.length) return;
    setTableFilter({
      ...tableFilter,
      size: newRowsPerPage,
    });
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getContacts({
      ...tableFilter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteContact = async (id: string) => {
    const { error }: any = await contactService.deleteContact(id);
    toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getContacts(tableFilter);
  }, [tableFilter]);

  // Flter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getContacts(tableFilter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getContacts(tableFilter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("CrmPages.ContactPageTitle")}
        parent={t("CrmPages.Crm")}
        title={t("CrmPages.ContactPageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <a href={Href} onClick={handleFilterToggle}>
                      <div className="light-box">
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </div>
                    </a>
                    <Link
                      className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/crm/contact/add`}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("CrmPages.AddContact")}
                    </Link>
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">{t("Common.name")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"name"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.Phone")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"phone"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.Email")}</Label>
                            <FilterInput
                              inputType={"email"}
                              inputName={"email"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.Company")}
                            </Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"company"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.Title")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"title"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              className="me-3"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              className="me-3"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={tableData ? tableData : []}
                    columns={tableColumns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={tableFilter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText"),
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
