import React, { useState } from 'react'
import Breadcrumbs from 'CommonElements/Breadcrumbs'
import { Card, CardBody, Col, Container, Form, FormFeedback, Label, Row } from 'reactstrap'
import { Btn } from 'AbstractElements'
import { EditProfileTitle, UsersTitle } from 'Utils/Constants'
import { useForm } from 'react-hook-form'
import { DatePicker } from 'antd'
import { BloodTypes } from 'Enums/User/BloodType'
import { useTranslation } from 'react-i18next'
import { GenderTypes } from 'Enums/User/GenderType'
import { ContractTypes } from 'Enums/User/ContractType'
import { EmploymentTypes } from 'Enums/User/EmploymentType'
import { MealCardTypes } from 'Enums/User/MealCardType'
import { WorkingTypes } from 'Enums/User/WorkingType'
import { User } from 'Types/User/UserType'

export default function EditProfile() {
  const { t } = useTranslation();
  const [validate, setValidate] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<User>();
  const onEditSubmit = () => {
    alert('Form is Submitted successfully');
  };

  const onChange = (date: any, dateString: any) => {
    console.log(date, dateString);
  };

  return (
    <div className='page-body'>
      <Breadcrumbs pageTitle={EditProfileTitle} parent={UsersTitle} title={EditProfileTitle} />
      <Container fluid>
        <div className="edit-profile">
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Form className="needs-validation custom-input" onSubmit={handleSubmit(onEditSubmit)} noValidate>
                    <Row className="g-3">
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.FirstName")}</Label>
                        <input type="text" className={`form-control ${errors.firstName ? 'is-invalid' : ''} ${validate && !errors.firstName ? 'is-valid' : ''}`} placeholder={t("Common.FirstName")} {...register("firstName", { required: t("FormValidations.ProfilePages.FirstNameRequired") })} />
                        {errors.firstName && (<FormFeedback tooltip>{errors.firstName.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.LastName")}</Label>
                        <input type="text" className={`form-control ${errors.lastName ? 'is-invalid' : ''} ${validate && !errors.lastName ? 'is-valid' : ''}`} placeholder={t("Common.LastName")} {...register("lastName", { required: t("FormValidations.ProfilePages.LastNameRequired") })} />
                        {errors.lastName && (<FormFeedback tooltip>{errors.lastName.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.IdentityNo")}</Label>
                        <input type="text" className={`form-control ${errors.identityNumber ? 'is-invalid' : ''} ${validate && !errors.identityNumber ? 'is-valid' : ''}`} placeholder={t("Common.IdentityNo")} {...register("identityNumber", { required: t("FormValidations.ProfilePages.IdentityNumberRequired") })} />
                        {errors.identityNumber && (<FormFeedback tooltip>{errors.identityNumber.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.Email")}</Label>
                        <input type="text" className={`form-control ${errors.email ? 'is-invalid' : ''} ${validate && !errors.email ? 'is-valid' : ''}`} placeholder="abc@mail.com" {...register("email", { required: t("FormValidations.ProfilePages.EmailRequired") })} />
                        {errors.email && (<FormFeedback tooltip>{errors.email.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.Phone")}</Label>
                        <input type="text" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''} ${validate && !errors.phoneNumber ? 'is-valid' : ''}`} placeholder="5351234567" {...register("phoneNumber", { required: t("FormValidations.ProfilePages.PhoneRequired") })} />
                        {errors.phoneNumber && (<FormFeedback tooltip>{errors.phoneNumber.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.Birthday")}</Label>
                        {/* <input className={`digits form-control ${errors.birthdate ? 'is-invalid' : ''} ${validate && !errors.birthdate ? 'is-valid' : ''}`} type={"date"} {...register("birthdate", { required: "Please enter your phone birth date" })} /> */}
                        <DatePicker onChange={onChange} className={`form-control ${errors.birthdate ? 'is-invalid' : ''} ${validate && !errors.birthdate ? 'is-valid' : ''}`} required />
                        {errors.birthdate && (<FormFeedback tooltip>{errors.birthdate.message}</FormFeedback>)}
                      </Col>

                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.Gender")}</Label>
                        <select className={`form-control ${errors.genderType ? 'is-invalid' : ''} ${validate && !errors.genderType ? 'is-valid' : ''}`} {...register("genderType", { required: t("FormValidations.ProfilePages.GenderTypeRequired") })}>
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.values(GenderTypes).map((value: string, ind: number) => (
                            <option key={ind} value={Object.keys(GenderTypes)[ind]}>
                              {t(`Common.${value}`)}
                            </option>
                          ))}
                        </select>
                        {errors.genderType && (<FormFeedback tooltip>{errors.genderType.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.BloodType")}</Label>
                        <select className={`form-control ${errors.bloodType ? 'is-invalid' : ''} ${validate && !errors.bloodType ? 'is-valid' : ''}`} {...register("bloodType", { required: t("FormValidations.ProfilePages.BloodTypeRequired") })}>
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.values(BloodTypes).map((value: string, ind: number) => (
                            <option key={ind} value={Object.keys(BloodTypes)[ind]}>
                              {value}
                            </option>
                          ))}
                        </select>
                        {errors.bloodType && (<FormFeedback tooltip>{errors.bloodType.message}</FormFeedback>)}
                      </Col>

                      <Col md={12} className="position-relative">
                        <Label>{t("Common.Contact")}</Label>
                        <input type="text" className={`form-control ${errors.contact ? 'is-invalid' : ''} ${validate && !errors.contact ? 'is-valid' : ''}`} placeholder={t("Common.Contact")} {...register("contact", { required: t("FormValidations.ProfilePages.ContactRequired") })} />
                        {errors.contact && (<FormFeedback tooltip>{errors.contact.message}</FormFeedback>)}
                      </Col>
                      <Col md={12} className="position-relative">
                        <Label>{t("Common.Address")}</Label>
                        <textarea className={`form-control ${errors.address ? 'is-invalid' : ''} ${validate && !errors.address ? 'is-valid' : ''}`} {...register("address", { required: t("FormValidations.ProfilePages.AddressRequired") })} />
                        {errors.address && (<FormFeedback tooltip>{errors.address.message}</FormFeedback>)}
                      </Col>

                      <hr />

                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("ProfilePages.RegistrationNo")}</Label>
                        <input type="text" className={`form-control ${errors.registrationNumber ? 'is-invalid' : ''} ${validate && !errors.registrationNumber ? 'is-valid' : ''}`} placeholder={t("ProfilePages.RegistrationNo")} {...register("registrationNumber", { required: t("FormValidations.ProfilePages.RegistrationNoRequired") })} />
                        {errors.registrationNumber && (<FormFeedback tooltip>{errors.registrationNumber.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("Common.Supervisor")}</Label>
                        <input type="text" className={`form-control ${errors.supervisor ? 'is-invalid' : ''} ${validate && !errors.supervisor ? 'is-valid' : ''}`} placeholder={t("Common.Supervisor")} {...register("supervisor", { required: t("FormValidations.ProfilePages.SupervisorRequired") })} />
                        {errors.supervisor && (<FormFeedback tooltip>{errors.supervisor.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("ProfilePages.JobStartDate")}</Label>
                        <DatePicker onChange={onChange} className={`form-control ${errors.jobStartDate ? 'is-invalid' : ''} ${validate && !errors.jobStartDate ? 'is-valid' : ''}`} required />
                        {errors.jobStartDate && (<FormFeedback tooltip>{errors.jobStartDate.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("ProfilePages.ContractType")}</Label>
                        <select className={`form-control ${errors.contractType ? 'is-invalid' : ''} ${validate && !errors.contractType ? 'is-valid' : ''}`} {...register("contractType", { required: t("FormValidations.ProfilePages.ContractTypeRequired") })}>
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.values(ContractTypes).map((value: string, ind: number) => (
                            <option key={ind} value={Object.keys(ContractTypes)[ind]}>
                              {t(`Common.${value}`)}
                            </option>
                          ))}
                        </select>
                        {errors.contractType && (<FormFeedback tooltip>{errors.contractType.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("ProfilePages.ContractStartDate")}</Label>
                        <DatePicker onChange={onChange} className={`form-control ${errors.contractStartDate ? 'is-invalid' : ''} ${validate && !errors.contractStartDate ? 'is-valid' : ''}`} required />
                        {errors.contractStartDate && (<FormFeedback tooltip>{errors.contractStartDate.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("ProfilePages.ContractEndDate")}</Label>
                        <DatePicker onChange={onChange} className={`form-control ${errors.contractEndDate ? 'is-invalid' : ''} ${validate && !errors.contractEndDate ? 'is-valid' : ''}`} required />
                        {errors.contractEndDate && (<FormFeedback tooltip>{errors.contractEndDate.message}</FormFeedback>)}
                      </Col>

                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("ProfilePages.EmploymentType")}</Label>
                        <select className={`form-control ${errors.employmentType ? 'is-invalid' : ''} ${validate && !errors.employmentType ? 'is-valid' : ''}`} {...register("employmentType", { required: t("FormValidations.ProfilePages.EmploymentTypeRequired") })}>
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.values(EmploymentTypes).map((value: string, ind: number) => (
                            <option key={ind} value={Object.keys(EmploymentTypes)[ind]}>
                              {t(`Common.${value}`)}
                            </option>
                          ))}
                        </select>
                        {errors.employmentType && (<FormFeedback tooltip>{errors.employmentType.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("ProfilePages.MealCardType")}</Label>
                        <select className={`form-control ${errors.mealCardType ? 'is-invalid' : ''} ${validate && !errors.mealCardType ? 'is-valid' : ''}`} {...register("mealCardType", { required: t("FormValidations.ProfilePages.MealCardTypeRequired") })}>
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.values(MealCardTypes).map((value: string, ind: number) => (
                            <option key={ind} value={Object.keys(MealCardTypes)[ind]}>
                              {t(value)}
                            </option>
                          ))}
                        </select>
                        {errors.mealCardType && (<FormFeedback tooltip>{errors.mealCardType.message}</FormFeedback>)}
                      </Col>
                      <Col md={6} xs={12} className="position-relative">
                        <Label>{t("ProfilePages.WorkingType")}</Label>
                        <select className={`form-control ${errors.workingType ? 'is-invalid' : ''} ${validate && !errors.workingType ? 'is-valid' : ''}`} {...register("workingType", { required: t("FormValidations.ProfilePages.WorkingTypeRequired") })}>
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.values(WorkingTypes).map((value: string, ind: number) => (
                            <option key={ind} value={Object.keys(WorkingTypes)[ind]}>
                              {t(`Common.${value}`)}
                            </option>
                          ))}
                        </select>
                        {errors.workingType && (<FormFeedback tooltip>{errors.workingType.message}</FormFeedback>)}
                      </Col>
                      <br />
                      <Col xs={12} className="float-end">
                        <Btn color="primary">{t("Common.Update")}</Btn>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
