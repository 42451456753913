import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as laboratoryService from "Services/MetriksServices/Laboratory";

// Types
import { BarcodingType } from "Enums/Metriks/BarcodingType";
import { LaboratoryReportingType } from "Enums/Metriks/LaboratoryReportingType";
import { Switch } from "antd";
import { BarcodingStageType } from "Enums/Metriks/BarcodeStageType";
import { Laboratory } from "Types/Emission/Laboratory";
import { LaboratoryType } from "Types/Emission/LaboratoryType";
const barcodingTypes = BarcodingType;
const reportingTypes = LaboratoryReportingType;
const barcodingStageTypes = BarcodingStageType;

export default function UpdateLaboratory() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string | undefined }>();

  const [printParams, setPrintParams] = useState(false);

  const [laboratoryDetails, setLaboratoryDetails] = useState({} as Laboratory);

  const [laboratoryTypes, setLaboratoryTypes] = useState([]);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<any>({ shouldUnregister: false });

  const updateLabortory: SubmitHandler<any> = async (formData) => {
    const updatedData = {
      ...formData,
      type: {
        id: formData.type?.id,
      },
      id: laboratoryDetails?.id,
      printParameters: printParams,
      active: laboratoryDetails.active,
    };
    const { error }: any = await laboratoryService.updateLaboratory(
      updatedData
    );
    navigate(`${process.env.PUBLIC_URL}/experiments/laboratory/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
  };

  const getLaboratoryById = async () => {
    const { data, error }: any = await laboratoryService.getLaboratoryById(id);
    setLaboratoryDetails(data?.body);
    setValue("type", data?.body?.type?.type);
    setValue("barcodingType", data?.body?.barcodingType);
    setValue("reportingType", data?.body?.reportingType);
    setValue("barcodingStageType", data?.body?.barcodingStageType);
    setPrintParams(data?.body?.printParameters);
    setValue("name", data?.body?.name);
    setValue("code", data?.body?.code);
    setValue("barcodingCode", data?.body?.barcodingCode);
    if (error) toast.error(error?.meta.errorDescription);
  };

  useEffect(() => {
    getLaboratoryById();
  }, [id]);

  const getLaboratoryTypes = async () => {
    const { data, error }: any = await laboratoryService.getLaboratoryTypeAll();
    setLaboratoryTypes(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  useEffect(() => {
    getLaboratoryTypes();
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("ExperimentPages.LaboratoryPages.UpdateLaboratory")}
        parent={t("ExperimentPages.LaboratoryPages.Metriks")}
        subParent={t("ExperimentPages.LaboratoryPages.LaboratoryPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/experiments/laboratory/list`}
        title={t("ExperimentPages.LaboratoryPages.UpdateLaboratory")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(updateLabortory)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t(
                            "ExperimentPages.LaboratoryPages.measurementType"
                          ) + " *"}
                        </Label>
                        <select
                          className="form-control"
                          defaultValue={laboratoryDetails?.type}
                          {...register("type", { required: true })}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {laboratoryTypes.map((labType: LaboratoryType) => (
                            <option
                              key={labType.id}
                              value={labType.id}
                              selected={
                                labType.type === laboratoryDetails?.type?.type
                              }
                            >
                              {t(
                                `ExperimentPages.LaboratoryPages.${labType.displayName}`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.measurementType &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.MeasurementTypeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.name")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.name")} *`}
                          {...register("name", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.NameRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.code")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.code")} *`}
                          {...register("code", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.code &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.CodeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.barcodingCode")}
                        </Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t(
                            "ExperimentPages.LaboratoryPages.barcodingCode"
                          )}`}
                          {...register("barcodingCode", { required: false })}
                        />
                        <span className="form-error-text">
                          {errors.barcodingCode &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.BarcodingCodeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.barcoding") +
                            " *"}
                        </Label>
                        <select
                          className="form-control"
                          defaultValue={laboratoryDetails?.barcodingType}
                          {...register("barcodingType", { required: true })}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(barcodingTypes).map((key) => (
                            <option
                              key={key}
                              value={key}
                              selected={
                                key === laboratoryDetails?.barcodingType
                              }
                            >
                              {t(
                                `ExperimentPages.LaboratoryPages.${
                                  BarcodingType[
                                    key as keyof typeof BarcodingType
                                  ]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.barcodingType &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.BarcodingTypeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.barcodingStage") +
                            " *"}
                        </Label>
                        <select
                          className="form-control"
                          defaultValue={laboratoryDetails?.barcodingStageType}
                          {...register("barcodingStageType", {
                            required: true,
                          })}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(barcodingStageTypes).map((key) => (
                            <option
                              key={key}
                              value={key}
                              selected={
                                key === laboratoryDetails?.barcodingStageType
                              }
                            >
                              {t(
                                `ExperimentPages.LaboratoryPages.${
                                  BarcodingStageType[
                                    key as keyof typeof BarcodingStageType
                                  ]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.barcodingStageType &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.BarcodingStageRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-4 h-100">
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.printParameters")}
                        </Label>
                        <div className="d-flex align-items-center">
                          <Switch
                            className="me-2"
                            value={laboratoryDetails?.printParameters}
                            checked={printParams}
                            onChange={(e: any) => {
                              setPrintParams(e);
                            }}
                          />
                          {t(
                            "ExperimentPages.LaboratoryPages.printParametersText"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.reporting") +
                            " *"}
                        </Label>
                        <select
                          className="form-control"
                          defaultValue={laboratoryDetails?.reportingType}
                          {...register("reportingType", { required: true })}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(reportingTypes).map((key) => (
                            <option
                              key={key}
                              value={key}
                              selected={
                                key === laboratoryDetails?.reportingType
                              }
                            >
                              {t(
                                `ExperimentPages.LaboratoryPages.${
                                  LaboratoryReportingType[
                                    key as keyof typeof LaboratoryReportingType
                                  ]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.reportingType &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.ReportingTypeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.update")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/experiments/laboratory/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
