import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { DebitType } from "Types/Debit/DebitType";

export const getDebits = async (debitListFilter: ListFilterType, filterObj: any) => {
  const filterParams = {
    productType: filterObj?.productType,
    quantity: filterObj?.quantity,
    maintenance: filterObj?.maintenance,
    createdDate: filterObj?.createdDate
  };
  return request.get(
    `${Paths.debit}/main/find-all/paginated`,
    {
      page: debitListFilter.page,
      size: debitListFilter.size,
      ...(debitListFilter?.sort && {sort: debitListFilter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getDebitById = async (id: any) => {
  return request.get(
    `${Paths.debit}/main/find-by-id/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteDebit = async (debitId: string) => {
  return request.delete(
    `${Paths.debit}/main/toggle-delete/${debitId}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateDebit = async (debit: DebitType) => {
  return request.put(`${Paths.debit}/main/update`, debit, {
    headers: Headers.headers,
  });
};

export const saveDebitAction = async (debit: DebitType) => {
  return request.post(`${Paths.debit}/main/save`, debit, {}, {
    headers: Headers.headers,
  });
};

export const saveDebitReturnAction = async (debit: DebitType) => {
  return request.post(`${Paths.debit}/main/return-debit`, debit, {}, {
    headers: Headers.headers,
  });
};