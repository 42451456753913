import React, {useEffect, useState} from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {Card, CardBody, Col, Container, Row,} from "reactstrap";
import {useTranslation} from "react-i18next";
import {dateFormats, defaultPageAndSize, defaultPageAndSizeForAll, Done} from "Utils/Constants";
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

// Services
import * as workOrderService from "Services/EmissionServices/WorkOrder";
import * as workService from "Services/EmissionServices/Work";
import * as workNodeService from "Services/EmissionServices/WorkNode";

// Types
import {ListFilterType} from "Types/Common/ListFilterType";
import {Steps} from "antd";
import dayjs from "dayjs";
import {Badges, H3, P, ProgressBar} from "../../../../AbstractElements";
import {Status} from "Enums/Common/Status";
import {LaboratoryMeasurementTypes} from "Enums/Metriks/LaboratoryMeasurementType";
import {WorkRootType} from "Enums/Emission/WorkRootType";

export default function WorkDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string | undefined }>();
  
  const [tableFilter] = useState(
    defaultPageAndSize as ListFilterType
  );

  const [tableFilterWorkNode] = useState(
    {
      ...defaultPageAndSizeForAll as ListFilterType, sort: ["sequenceNo,ASC"]
    }
  );
  
  const [stepsData, setStepsData] = useState<any>([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [workNodes, setWorkNodes] = useState([]);
  const [workDetails, setWorkDetails] = useState<any>(null);
  
  const getWork = async () => {
    const { data, error }: any = await workService.getWorkById(id);
    if (error) {toast.error(t(`ERRORS.${error?.errorCode}`));}
    else {
      setWorkDetails(data?.body);
      return data?.body;
    }
  }

  const getWorkOrders = async () => {
    const { data, error }: any = await workOrderService.getWorkOrders(tableFilter, {workId: id});
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      setWorkOrders(data?.body?.content);
      return data?.body?.content;
    }
  };

  const getWorkNodes = async (laboratoryId: any) => {
    const { data, error }: any = await workNodeService.getWorkNodes(tableFilterWorkNode, {laboratory: laboratoryId});
    if (error) {toast.error(t(`ERRORS.${error?.errorCode}`));}
    else {
      setWorkNodes(data?.body?.content);
      return data?.body?.content;
    }
  }
  
  const mergeStepsData = (work: any, workNodes: any, workOrders: any) => {
    let steps: any[] = [{
      title: t('ExperimentPages.WorkPages.JobCreated'),
      description: work?.createdDate ? dayjs(work.createdDate).format(dateFormats.D_M_Y_H_m) : "",
      status: 'finish',
      style: {cursor: 'pointer',}
    }];
    workNodes.forEach((workNode: any) => {
      let workOrder: any = workOrders.filter((value : any) => value?.nodeRootType?.id === workNode?.type?.id);
      let step = {
        title: t(`EmissionPages.${WorkRootType[workNode?.type?.displayName as keyof typeof WorkRootType]}`),
        description: workOrder[0]?.createdDate ? dayjs(workOrder[0].createdDate).format(dateFormats.D_M_Y_H_m) : "",
        status: findStatus(workOrder),
        onClick: () => {navigate(`/offers/offer/add/${id}`)},
        //onClick: () => {navigate(`/offers/offer/add/${workOrder[0]?.id}`)},
        style: {cursor: 'pointer',}
        //onClick: (workNode?.uiPath && workOrder?.id) ? navigate(`${workNode?.uiPath}/${workOrder?.id}`) : navigate(`/offers/offer/add/${workOrder?.id}`),
      }
      steps.push(step);
    })
    steps.push({
      title: t('ExperimentPages.WorkPages.JobFinished'),
      description: "",
      style: {cursor: 'pointer',}
    })
    setStepsData(steps);
  }

  useEffect(() => {
    const fetchData = async () => {
      const work = await getWork();
      if (work) {
        const orders = await getWorkOrders();
        if (orders) {
          const nodes = await getWorkNodes(work?.laboratory?.id);
          if (nodes) {
            mergeStepsData(work, nodes, orders);
          }
        }
      }
    }
    fetchData();
  }, []);
  
  const calculateCurrentIndex = () => {
    workOrders.forEach((workOrder: any, index: number) => {
      if (['IN_PROGRESS', 'SAVED'].includes(workOrder.status)) return index;
      else if (['IN_PROGRESS', 'SAVED'].includes(workOrder.status) && workOrder?.ghostNode) return index;
      else if (['FAILED', 'CANCELLED', 'REJECTED'].includes(workOrder.status)) return index;
    })
    return workOrders.length;
  }

  function findStatus(workOrder: any) {
      if (['IN_PROGRESS', 'SAVED'].includes(workOrder.status)) return 'process';
      else if (['IN_PROGRESS', 'SAVED'].includes(workOrder.status)) return 'finish';
      else if (['FAILED', 'CANCELLED', 'REJECTED'].includes(workOrder.status)) return 'fail';
  }
  
  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("ExperimentPages.WorkPages.WorkDetailPageTitle")}
        parent={t("ExperimentPages.WorkPages.Works")}
        title={t("ExperimentPages.WorkPages.WorkDetailPageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product">
                  <Row className="mb-4">
                    <Col sm={12}>
                      <Link className="btn btn-outline-light" to={`/emission/work/edit/${workDetails?.workGroup?.id}`}>
                        <i className="fa fa-chevron-left me-2"></i>
                        <span className="fw-semibold">{t("Common.Back")}</span>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Steps
                        direction="vertical"
                        current={calculateCurrentIndex()}
                        items={stepsData}
                      />
                    </Col>
                    <Col md={8} className='box-col-33'>
                      <div className='project-box'>
                        <Badges color={'secondary'}>{t(`Common.${Status[workDetails?.status as keyof typeof Status]}`)}</Badges>
                        <H3>{workDetails?.code}</H3>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <P>{workDetails?.createdDate ? dayjs(workDetails?.createdDate).format(dateFormats.D_M_Y_H_m) : "-"}</P>
                          </div>
                        </div>
                        {
                          workDetails?.note ? <P>{workDetails?.note}</P> : null
                        }
                        <Row className='details'>
                          <Col lg={2}> 
                            <span>{t('ExperimentPages.WorkPages.Laboratory')} </span>
                          </Col>
                          <Col lg={10} className={`font-secondary`}>
                            {workDetails?.laboratory?.name}
                          </Col>
                          <Col lg={2}>
                            <span>{t('ExperimentPages.WorkPages.LaboratoryCode')} </span>
                          </Col>
                          <Col lg={10} className={`font-secondary`}>
                            {workDetails?.laboratory?.code}
                          </Col>
                          <Col lg={2}>
                            <span>{t('ExperimentPages.WorkPages.LaboratoryType')} </span>
                          </Col>
                          <Col lg={10} className={`font-secondary`}>
                            {t(`ExperimentPages.LaboratoryPages.${LaboratoryMeasurementTypes[workDetails?.laboratory?.type?.displayName as keyof typeof LaboratoryMeasurementTypes]}`)}
                          </Col>
                        </Row>
                        <div className='project-status mt-4'>
                          <div className="d-flex mb-0">
                            <P>{'50'}{'%'}</P>
                            <div className="flex-grow-1 text-end">
                              <span>{t('Common.Done')}</span>
                            </div>
                          </div>
                          <ProgressBar className='sm-progress-bar'
                                       color={'100' === '100' ? 'success' : 'secondary'} value={50}
                                       striped={'100' !== '100' ? true : false}/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
