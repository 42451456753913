import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href, defaultPageAndSize } from "Utils/Constants";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { P, UL, LI, Btn } from "AbstractElements";
import { ListFilterType } from "Types/Common/ListFilterType";
import { toast } from "react-toastify";
import { Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import FilterSelect from "Components/Filters/FilterSelect";
import FilterInput from "Components/Filters/FilterInput";

// Services
import * as vehicleService from "Services/DebitServices/Vehicle";

// Types
import { VehicleType } from "Types/Debit/VehicleType";
import { FuelTypes } from "Enums/Debit/Fuel";
import { GearTypes } from "Enums/Debit/Gear";

export default function Vehicle() {
  const { t } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };
  const [vehicleListData, setVehicleListData] = useState([] as VehicleType[]);

  const [vehicleListFilter, setVehicleListFilter] = useState(
    defaultPageAndSize as ListFilterType
  );

  const [loadingTable, setLoadingTable] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const tableListColumns: TableColumn<any>[] = [
    {
      name: t("DebitPages.Vehicle.plate"),
      sortField: "licencePlate",
      selector: (row) => row.licencePlate,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.licencePlate}</P>,
    },
    {
      name: t("DebitPages.Brand.brand"),
      sortField: "brand.name",
      selector: (row) => row.brand?.name,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.brand?.name}</P>,
    },
    {
      name: t("DebitPages.Model"),
      sortField: "model",
      selector: (row) => row.model,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.model}</P>,
    },
    {
      name: t("Common.year"),
      sortField: "modelYear",
      selector: (row) => row.modelYear,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.modelYear}</P>,
    },
    {
      name: t("DebitPages.Vehicle.fuelType"),
      sortField: "fuelType",
      selector: (row) => row.fuelType,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{t(`Common.${row.fuelType}`)}</P>,
    },
    {
      name: t("DebitPages.Vehicle.gearType"),
      sortField: "gearType",
      selector: (row) => row.gearType,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{t(`Common.${row.gearType}`)}</P>,
    },
    {
      name: t("Common.createdDate"),
      sortField: "createdDate",
      width: "200px",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {dayjs(row.createdDate).format("DD-MM-YYYY HH:mm")}
        </P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="edit">
              <Tooltip placement="bottom" title={t("Common.Edit")}>
                <Link
                  to={`${process.env.PUBLIC_URL}/debit/vehicle/edit/${row.id}`}
                >
                  <i className="icon-pencil-alt" />
                </Link>
              </Tooltip>
            </LI>
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteVehicle(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getVehicles = async (vehicleListFilter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any = await vehicleService.getVehicles(
      vehicleListFilter,
      filterObj
    );
    setLoadingTable(false);
    setVehicleListData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(false);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getVehicles({
      ...vehicleListFilter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteVehicle = async (vehicleId: string) => {
    const { error }: any = await vehicleService.deleteVehicle(vehicleId);
    toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
    getVehicles(vehicleListFilter);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getVehicles(vehicleListFilter);
  }, [vehicleListFilter]);

  const handlePageChange = (page: number) => {
    setVehicleListFilter({
      ...vehicleListFilter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!vehicleListData.length) return;
    setVehicleListFilter({
      ...vehicleListFilter,
      size: newRowsPerPage,
    });
  };

  // Flter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getVehicles(vehicleListFilter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getVehicles(vehicleListFilter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.VehiclePageTitle")}
        parent={t("DebitPages.Debit")}
        title={t("DebitPages.VehiclePageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <a href={Href} onClick={handleFilterToggle}>
                      <div className="light-box">
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </div>
                    </a>
                    <Link
                      className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/debit/vehicle/add`}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("DebitPages.AddVehicle")}
                    </Link>
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">
                              {t("DebitPages.Vehicle.plate")}
                            </Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"licencePlate"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.brand")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"brand"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("DebitPages.Model")}
                            </Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"model"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.year")}</Label>
                            <FilterInput
                              inputType={"number"}
                              inputName={"year"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("DebitPages.Vehicle.fuelType")}
                            </Label>
                            <FilterSelect
                              inputName={"fuelType"}
                              setFilterObjFun={setFilterObj}
                              isStatus={false}
                              options={Object.keys(FuelTypes).map((key) => {
                                return {
                                  text: t(
                                    `Common.${
                                      FuelTypes[key as keyof typeof FuelTypes]
                                    }`
                                  ),
                                  value: `${key}`,
                                };
                              })}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("DebitPages.Vehicle.gearType")}
                            </Label>
                            <FilterSelect
                              inputName={"gearType"}
                              setFilterObjFun={setFilterObj}
                              isStatus={false}
                              options={Object.keys(GearTypes).map((key) => {
                                return {
                                  text: t(
                                    `Common.${
                                      GearTypes[key as keyof typeof GearTypes]
                                    }`
                                  ),
                                  value: `${key}`,
                                };
                              })}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              className="me-3"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              className="me-3"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={vehicleListData ? vehicleListData : []}
                    columns={tableListColumns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={vehicleListFilter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText"),
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
