import React from 'react'
import DefaultDashboard from '../../../Components/Dashboards/Default'

export default function Default() {
  return (
    <div className='page-body'>
      <DefaultDashboard />
    </div>
  )
}
