import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { dateFormats } from "Utils/Constants";
const { RangePicker } = DatePicker;

export default function FilterInput({
  inputType,
  inputName,
  inputPlaceholder,
  setFilterObjFun,
  clearSignal,
}: any) {
  const [inputValue, setInputValue] = useState("");
  const [inputDateValue, setInputDateValue] = useState(null);

  useEffect(() => {
    if (clearSignal) {
      setInputValue("");
      setInputDateValue(null);
    }
  }, [clearSignal]);

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    if (e.target.value !== "") {
      setFilterObjFun((prevData: any) => ({
        ...prevData,
        [inputName]: e.target.value,
      }));
    } else {
      setFilterObjFun((prevData: any) => {
        const newData: any = { ...prevData };
        delete newData[inputName];
        return newData;
      });
    }
  };

  const handleDateChange = (e: any) => {
    setInputDateValue(e);
    if (e !== "" || e !== null) {
      setFilterObjFun((prevData: any) => ({
        ...prevData,
        [inputName]: e,
      }));
    } else {
      setFilterObjFun((prevData: any) => {
        const newData: any = { ...prevData };
        delete newData[inputName];
        return newData;
      });
    }
  };

  return (
    <div>
      {inputType !== "date" && inputType !== "daterange" && (
        <Input
          type={inputType}
          name={inputName}
          value={inputValue}
          placeholder={inputPlaceholder ? inputPlaceholder : ""}
          onChange={(e) => handleChange(e)}
        />
      )}
      {/* single date picker */}
      {inputType === "date" && (
        <DatePicker
          className="form-control"
          name={inputName}
          value={inputDateValue ? dayjs(inputDateValue) : null}
          onChange={(e) =>
            handleDateChange(dayjs(e).format(dateFormats.D_M_Y_H_m_s_Z))
          }
        />
      )}
      {/* date range picker */}
      {inputType === "daterange" && (
        <RangePicker
          name={inputName}
          value={
            inputDateValue
              ? [dayjs(inputDateValue?.[0]), dayjs(inputDateValue?.[1])]
              : null
          }
          onChange={(e) =>
            handleDateChange([
              dayjs(e?.[0]).format(dateFormats.D_M_Y_H_m_s_Z),
              dayjs(e?.[1]).format(dateFormats.D_M_Y_H_m_s_Z),
            ])
          }
        />
      )}
    </div>
  );
}
