import { toast } from "react-toastify";

const stateError = (error: any) => {
  const { response = {} } = error;
  const { status } = response;

  if (!status) return { error: 'Connection Error' };

  if (status === 401) {
    localStorage.clear();
    window.location.replace(`${process.env.PUBLIC_URL}/login`);
  }

  if (status === 400) {
    toast.error("Bir hata oluştu!");
  }

  // if (Object.prototype.toString.call(data) === '[object Object]') {
  //   const { errors, message } = data;
  //   return checkError(status, errors, message);
  // } else {
  //   const { message } = error;
  //   return checkError(null, null, message);
  // }

  return { error: response.data.meta };
};

const checkError = async (status: any, error: any, message: any) => {
  message === '' && (message = 'An error occurred due to technical reasons');

  if (Object.prototype.toString.call(error) === '[object Object]') {
    const keys = Object.keys(error);
    let errorContent = '';
    for (let i = 0; i < keys.length; i++) {
      const item = keys[i];
      const errorItem = error[item];
      errorContent = errorContent + errorItem + '\n';
      if (i === keys.length - 1) {
        return { error: errorContent };
      }
    }
  }

  if (typeof message === 'string') return { error: message };

  if (typeof error === 'string') return { error };

  if (status === 500) return { error: 'Server Error. Error Code: 500' };


  return { error: 'An error occurred due to technical reasons' };
};

export { stateError, checkError };