// Login
export const login = 'auth-login';

// Logout
export const logout = 'api/v1/auth';

// User Group
export const userGroup = 'auth/group';

// Role
export const role = 'auth/role';

// Permission
export const permission = 'auth/permission';

// User
export const user = 'auth/users';

// User Detail
export const userDetail = 'user/details';

// Customer
export const customer = 'customer';

// Partner
export const partner = 'partner';

// Project
export const project = 'project';

// Debit
export const debit = 'debit';

// Crm
export const crm = 'crm';

//Works
export const emission = 'emission';

// Laboratory
export const laboratory = 'laboratory';

// Node
export const node = 'node';