import request from "Services/files/Request";
import * as Paths from "Services/files/Paths";
import {WorkCreate} from "Types/Emission/Work";
import {ListFilterType} from "Types/Common/ListFilterType";
import * as Headers from "Services/files/Headers";

export const getWorkById = async (id: any) => {
  return request.get(
    `${Paths.emission}/work?id=${id}`,
    {},
    { headers: Headers.headers }
  );
};

export const getWorks = async (listFilter: ListFilterType, filterObj: any) => {
  return request.get(
    `${Paths.emission}/work/find-all/paginated`,
    {
      page: listFilter.page,
      size: listFilter.size,
      ...(listFilter?.sort && {sort: listFilter?.sort[0]}),
      ...filterObj
    },
    { headers: Headers.headers }
  );
};

export const saveWork = async (workCreate: WorkCreate) => {
  return request.post(`${Paths.emission}/work`, workCreate);
};