import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import DropdownWithHeader from "../../../../../Utils/CommonComponents/DashboardsCommon/DropdownWithHeader";
import { monthlyDropdownList } from "../../../../../Data/DashboardsData/DefaultData";
import {
  MontlyTarget,
  StatisticsTitle,
  WeeklyTarget,
} from "../../../../../Utils/Constants";
import { H4, ProgressBar } from "../../../../../AbstractElements";

export default function StatisticsCard() {
  const { t } = useTranslation();

  return (
    <Col xl={12} md={6} className="pe-0">
      <Card className="statistics">
        <DropdownWithHeader
          headerClass="card-no-border pb-0"
          heading={t("Common.Statistics")}
          dropDownList={monthlyDropdownList}
          dropDownClass="icon-dropdown"
          dropDownIcon={true}
        />
        <CardBody>
          <div className="d-flex">
            <div className="flex-shrink-0">
              <H4>{t("Common.WeeklyTarget")}</H4>
              <span>{"25%"} {t("Common.achieved")}</span>
              <ProgressBar color="primary" value={50} />
            </div>
            <div className="flex-grow-1">
              <H4>{t("Common.MonthlyTarget")}</H4>
              <span>{"40%"} {t("Common.achieved")}</span>
              <ProgressBar color="secondary" value={85} />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
