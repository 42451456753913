import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";

export const getInventories = async (inventoryListFilter: ListFilterType, filterObj: any) => {
  const filterParams = {
    productState: filterObj?.productState,
    productType: filterObj?.productType,
    stock: filterObj?.stock,
    controlPeriod: filterObj?.controlPeriod
  };
  return request.get(
    `${Paths.debit}/inventory/find-all/paginated`,
    {
      page: inventoryListFilter.page,
      size: inventoryListFilter.size,
      ...(inventoryListFilter?.sort && {sort: inventoryListFilter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getInventoryById = async (id: any) => {
  return request.get(
    `${Paths.debit}/inventory/find-by-id/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteInventory = async (inventoryId: string) => {
  return request.delete(
    `${Paths.debit}/inventory/toggle-delete/${inventoryId}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateInventory = async (inventory: any) => {
  return request.put(`${Paths.debit}/inventory/update`, inventory, {
    headers: Headers.headers,
  });
};

export const addInventory = async (inventory: any) => {
  return request.post(`${Paths.debit}/inventory/create`, inventory, {}, {
    headers: Headers.headers,
  });
};

