import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href, defaultPageAndSize } from "Utils/Constants";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { P, UL, LI, Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import FilterInput from "Components/Filters/FilterInput";

// Services
import * as experimentService from "Services/MetriksServices/Experiment";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { Experiment } from "Types/Emission/Experiment";

export default function ExperimentList() {
  const { t } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const [experimentListData, setExperimentListData] = useState(
    [] as Experiment[]
  );
  const [filter, setFilter] = useState(defaultPageAndSize as ListFilterType);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const columns: TableColumn<any>[] = [
    {
      name: t("Common.name"),
      sortField: "name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.name}</P>,
    },
    {
      name: t("ExperimentPages.LaboratoryPages.measurementType"),
      sortField: "criterionType",
      selector: (row) => row.laboratory?.type?.type,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {row.laboratory?.type?.type === "WASTE_WATER"
            ? t("ExperimentPages.ExperimentPages.WasteWaterType")
            : row.laboratory?.type?.type === "EMISSION"
            ? t("ExperimentPages.ExperimentPages.EmissionType")
            : "-"}
        </P>
      ),
    },
    {
      name: t("Common.code"),
      sortField: "code",
      selector: (row) => row.code,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.code ? row.code : "-"}</P>,
    },
    {
      name: t("Common.price"),
      sortField: "price",
      selector: (row) => row.price,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {row.price ? row.price : "-"} {"₺"}
        </P>
      ),
    },
    {
      name: t("Common.createdDate"),
      sortField: "createdDate",
      width: "200px",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {dayjs(row.createdDate).format("DD-MM-YYYY HH:mm")}
        </P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="edit">
              <Tooltip placement="bottom" title={t("Common.Edit")}>
                <Link
                  to={`${process.env.PUBLIC_URL}/experiments/experiment/edit/${row.id}`}
                >
                  <i className="icon-pencil-alt" />
                </Link>
              </Tooltip>
            </LI>
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteExperiment(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [loadingTable, setLoadingTable] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getExperiments = async (filter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any = await experimentService.getExperiments(
      filter,
      filterObj
    );
    setLoadingTable(false);
    setExperimentListData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(false);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const handlePageChange = (page: number) => {
    setFilter({
      ...filter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!experimentListData.length) return;
    setFilter({
      ...filter,
      size: newRowsPerPage,
    });
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getExperiments({
      ...filter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteExperiment = async (id: string) => {
    const { error }: any = await experimentService.deleteExperiment(id);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
      getExperiments(filter);
    }
  };

  useEffect(() => {
    getExperiments(filter);
  }, [filter]);

  // Flter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getExperiments(filter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getExperiments(filter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("ExperimentPages.ExperimentPages.ExperimentPageTitle")}
        parent={t("ExperimentPages.LaboratoryPages.Metriks")}
        title={t("ExperimentPages.ExperimentPages.ExperimentPageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <a href={Href} onClick={handleFilterToggle}>
                      <div className="light-box">
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </div>
                    </a>
                    <Link
                      className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/experiments/experiment/add`}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("ExperimentPages.ExperimentPages.AddExperiment")}
                    </Link>
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">{t("Common.name")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"name"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.code")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"code"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.price")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"price"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={experimentListData}
                    columns={columns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={filter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText"),
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
