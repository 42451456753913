import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as vehicleClassService from "Services/DebitServices/VehicleClass";

// Type
import { VehicleClassType } from "Types/Debit/VehicleClass";

export default function UpdateVehicleClass() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string | undefined }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue
  } = useForm<any>({shouldUnregister: false});

  const [vehicleClassDetails, setVehicleClassDetails] = useState(
    {} as VehicleClassType
  );

  const updateVehicleClass: SubmitHandler<any> = async (formData) => {
    const updatedData = {
      ...vehicleClassDetails,
      name: formData.name ? formData.name : vehicleClassDetails.name,
      description: formData.description
        ? formData.description
        : vehicleClassDetails.description,
    };
    const { error }: any = await vehicleClassService.updateVehicleClass(
      updatedData
    );
    navigate(`${process.env.PUBLIC_URL}/debit/vehicle-class/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
  };

  const getVehileClassById = async () => {
    const { data, error }: any = await vehicleClassService.getVehicleClassById(
      id
    );
    setVehicleClassDetails(data?.body);
    setValue("name", data?.body?.name);
    setValue("description", data?.body?.description);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getVehileClassById();
  }, [id]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.EditVehicleClass")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.VehicleClassPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/vehicle-class/list`}
        title={t("DebitPages.EditVehicleClass")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(updateVehicleClass)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.name")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={vehicleClassDetails?.name}
                          placeholder={`${t("Common.name")} *`}
                          {...register("name", {
                            validate: (value) =>
                              value !== "" || getValues("name") !== ""
                          })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t("FormValidations.DebitPages.NameRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.description")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          defaultValue={vehicleClassDetails?.description}
                          {...register("description", { required: false })}
                        ></textarea>
                        <span className="form-error-text">
                          {errors.features &&
                            t("FormValidations.DebitPages.DescriptionRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.update")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/vehicle-class/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
