import React from "react";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { SidebarMenuType } from "../../Types/LayoutTypes";
import { Pinned } from "../../Utils/Constants";
import { H6, LI, UL } from "../../AbstractElements";
import { menuList } from "../../Data/SidebarMenuList";
import SidebarSubMenu from "./SidebarSubMenu";
import BackButton from "./BackButton";
import { RootState } from "../../redux-toolkit/store";
import { useTranslation } from "react-i18next";
import { usePermissions } from "Hooks/usePermissions";

export default function SidebarNav() {
  const [activeMenu, setActiveMenu] = useState([]);
  const { pinedMenu } = useSelector((state: RootState) => state.layout);
  const { t } = useTranslation();
  const shouldHideMenu = (mainMenu: SidebarMenuType) => {
    return mainMenu.menu
      .map((data) => data.title)
      .every((tittles) => pinedMenu.includes(tittles as string));
  };

  const { hasPermission } = usePermissions();

  // dummy permmission list
  const userPermissions = ["view_dashboard", "view_works", "view_work_orders"];

  const filterMenu = (menu: any, userPermissions: any) => {
    return menu
      .filter((item: any) => {
        const parentHasPermission = hasPermission(
          userPermissions,
          item.permissions || []
        );
        const childMenu = item.menu
          ? filterMenu(item.menu, userPermissions)
          : [];
        const childHasPermission = childMenu.length > 0;
        return parentHasPermission || childHasPermission;
      })
      .map((item: any) => ({
        ...item,
        menu: item.menu ? filterMenu(item.menu, userPermissions) : undefined,
      }));
  };

  return (
    <UL className="sidebar-links simple-list custom-scrollbar" id="simple-bar">
      <div className="simplebar-wrapper">
        <div className="simplebar-mask">
          <div className="simplebar-offset">
            <div className="simplebar-content-wrapper">
              <div className="simplebar-content">
                <BackButton />
                {/* <LI className={`pin-title sidebar-main-title ${pinedMenu.length > 0 ? "show" : ""} `}>
                  <div>
                    <H6>{Pinned}</H6>
                  </div>
                </LI> */}
                {menuList &&
                  filterMenu(menuList, userPermissions).map(
                    (mainMenu: any, i: number) => (
                      <Fragment key={i}>
                        <LI
                          className={`sidebar-main-title ${
                            shouldHideMenu(mainMenu) ? "d-none" : ""
                          }`}
                        >
                          <div>
                            <H6 className="lan-1">{t(`${mainMenu.title}`)}</H6>
                          </div>
                        </LI>
                        {mainMenu.menu && mainMenu.menu.length > 0 && (
                          <SidebarSubMenu
                            menu={mainMenu.menu}
                            activeMenu={activeMenu}
                            setActiveMenu={setActiveMenu}
                            level={0}
                          />
                        )}
                        {/* <SidebarSubMenu menu={mainMenu.menu} activeMenu={activeMenu} setActiveMenu={setActiveMenu} level={0} /> */}
                      </Fragment>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UL>
  );
}
