import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

export default function FilterSelect({
  inputName,
  isStatus,
  setFilterObjFun,
  options,
  clearSignal,
}: any) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (clearSignal) {
      setInputValue("");
    }
  }, [clearSignal]);

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    if (e.target.value !== "") {
      setFilterObjFun((prevData: any) => ({
        ...prevData,
        [e.target.name]: isStatus ? JSON.parse(e.target.value) : e.target.value,
      }));
    } else {
      setFilterObjFun((prevData: any) => {
        const newData: any = { ...prevData };
        delete newData[e.target.name];
        return newData;
      });
    }
  };

  return (
    <Input
      type="select"
      name={inputName}
      onChange={(e) => handleChange(e)}
      value={inputValue}
    >
      <option value="">{t("Common.Select")}</option>
      {options.map((option: any, ind: number) => (
        <option key={ind} value={option.value}>
          {option.text}
        </option>
      ))}
    </Input>
  );
}
