import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { defaultPageAndSizeForAll } from "Utils/Constants";

// Permissions
export const getPermissions = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name
  };
  return request.get(
    `${Paths.permission}/find-all/paginated`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

// to do
export const getPermissionsAll = async () => {
  return request.get(
    `${Paths.permission}/find-all/paginated`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const deletePermission = async (id: string) => {
  return request.delete(
    `${Paths.permission}/delete/${id}`,
    {},
    {
      headers: Headers.headers,
    }
  );
};

export const addPermission = async (permission: any) => {
  return request.post(`${Paths.permission}/save`, permission, {}, {
    headers: Headers.headers,
  });
};
