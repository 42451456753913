import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Form as AntForm, Select } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";

// Services
import * as companyService from "Services/CrmServices/Company";
import * as contactService from "Services/CrmServices/Contact";

export default function AddContact() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [companyList, setCompanyList] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const getCompanies = async () => {
    const { data, error }: any = await companyService.getCompanies(
      { page: 0, size: 999 },
      {}
    );
    setCompanyList(data?.body?.content);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const addContact: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      company: {
        id: formData.company,
      },
    };
    const { error }: any = await contactService.addContact(newData);
    navigate(`${process.env.PUBLIC_URL}/crm/contact/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("CrmPages.ContactPageTitle")}
        parent={t("CrmPages.Crm")}
        subParent={t("CrmPages.ContactPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/crm/contact/list`}
        title={t("CrmPages.AddProject")}
      />
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <AntForm
                  className="theme-form"
                  onFinish={addContact}
                  autoComplete="off"
                >
                  <Row>
                    <Col>
                      <Label>{t("Common.FullName") + " *"}</Label>
                      <AntForm.Item
                        name="name"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.CrmPages.FullNameRequired"
                            ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={`${t("CrmPages.ContactFullName")} *`}
                        />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Label>{t("Common.Company") + " *"}</Label>
                      <AntForm.Item
                        name="company"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.CrmPages.CompanyRequired"
                            ),
                          },
                        ]}
                      >
                        <select className="form-control">
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {companyList.map((item: any, ind) => (
                            <option key={ind} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </AntForm.Item>
                    </Col>
                    <Col md={6}>
                      <Label>{t("Common.Title")}</Label>
                      <AntForm.Item
                        name="title"
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <Input placeholder={`${t("Common.Title")}`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Label>{t("Common.Phone") + " *"}</Label>
                      <AntForm.Item
                        name="phone"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.PhoneRequired"
                            ),
                          },
                        ]}
                      >
                        {/* <InputMask
                          mask="+90 (999) 999-99-99"
                          placeholder="+90 (___) ___-__-__"
                        >
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask> */}
                        <Input placeholder={`${t("Common.Phone")} *`} />
                      </AntForm.Item>
                    </Col>
                    <Col md={6}>
                      <Label>{t("Common.Email") + " *"}</Label>
                      <AntForm.Item
                        name="email"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            type: "email",
                            message: t(
                              "FormValidations.CrmPages.EmailValidType"
                            ),
                          },
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.EmailRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.Email")} *`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.Save")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/crm/contact/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </AntForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
