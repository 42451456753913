import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { dateFormats } from "Utils/Constants";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";

// Services
import * as brandService from "Services/DebitServices/Brand";
import * as disposableService from "Services/DebitServices/Disposable";

// Enums
import { DatePicker, Switch } from "antd";

export default function AddDisposable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [brandList, setBrandList] = useState([]);
  const [takeBeforeMesure, setTakeBeforeMesure] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");

  const addDisposable: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      brand: {
        id: formData.brand,
      },
      takeBeforeMesure: takeBeforeMesure,
      expiryDate: dayjs(expiryDate).format(dateFormats.D_M_Y_H_m_s_Z),
      productType: "DIPOSABLE",
    };
    const { error }: any = await disposableService.addDisposable(newData);
    navigate(`${process.env.PUBLIC_URL}/debit/disposable/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  const getBrands = async () => {
    const { data, error }: any = await brandService.getBrandsAll();
    setBrandList(data?.body?.content);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.AddDisposable")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.DisposablePageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/disposable/list`}
        title={t("DebitPages.AddDisposable")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(addDisposable)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.name") + " *"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.name")}`}
                          {...register("name", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t("FormValidations.DebitPages.NameRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.brand") + " *"}</Label>
                        <select
                          className="form-control"
                          {...register("brand", { required: true })}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {brandList.map((item: any, ind) => (
                            <option key={ind} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.brand &&
                            t("FormValidations.DebitPages.BrandRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.quantity") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder={`${t("Common.quantity")} *`}
                          {...register("quantity", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.quantity &&
                            t("FormValidations.DebitPages.QuantityRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup className="d-flex align-items-center h-100">
                        <Label>{t("Common.takeBeforeMesure") + " *"}</Label>
                        <div className="mx-3">
                          <Switch
                            checked={takeBeforeMesure}
                            onChange={(e: any) => {
                              setTakeBeforeMesure(e);
                            }}
                          />
                        </div>
                        <span className="form-error-text">
                          {errors.takeBeforeMesure &&
                            t("FormValidations.DebitPages.QuantityRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.expiryDate")}</Label>
                        <DatePicker
                          showTime
                          onChange={(e: any) => {
                            setExpiryDate(e);
                          }}
                          format={dateFormats.Y_M_D_H_m_s}
                          className={`form-control`}
                          required
                        />
                        <span className="form-error-text">
                          {errors.expiryDate &&
                            t("FormValidations.DebitPages.ExpiryDateRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.features")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          {...register("features", { required: false })}
                        ></textarea>
                        <span className="form-error-text">
                          {errors.features &&
                            t("FormValidations.DebitPages.FeaturesRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.add")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/disposable/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
