export enum LaboratoryMeasurementTypes {
  WATER_AND_WASTEWATER = "WaterAndWastewaterType",
  POOL_WATER = "PoolWaterType",
  EMISSION = "EmissionType",
  IMISSION = "ImissionType",
  OCCUPATIONAL_HEALTH_AND_SAFETY = "OccupationalHealthAndSafetyType",
  LIGHTING = "LightingType",
  VIBRATION = "VibrationType",
  INSTANT_GAS = "InstantGasType",
  THERMAL_COMFORT = "ThermalComfortType",
  DUST = "DustType",
  PERSONAL_NOISE = "PersonalNoiseType",
  ENVIRONMENTAL_NOISE = "EnvironmentalNoiseType",
  WASTE = "WasteType",
  WASTE_OIL = "WasteOilType",
  VOC = "VOCType",
  PERIODIC_CONTROL = "PeriodicControlType",
  OTHER = "OtherType"
};
