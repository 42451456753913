import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { DatePicker, Form as AntForm, Select as AntSelect } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as projectService from "Services/CrmServices/Project";
import * as companyService from "Services/CrmServices/Company";
import { dateFormats } from "Utils/Constants";
import { Company } from "Types/Crm/Company";
import dayjs from "dayjs";

export default function UpdateProject() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = AntForm.useForm();
  const { id } = useParams<{ id: string | undefined }>();

  const [companyList, setCompanyList] = useState([] as Company[]);
  const [selectedCompanies, setSelectedCompanies] = useState([] as Company[]);
  const [projectStartDate, setProjectStartDate] = useState();
  const [projectEndDate, setProjectEndDate] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const getCompanies = async () => {
    const { data, error }: any = await companyService.getCompaniesAll();
    setCompanyList(data?.body?.content);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const handleCompanySelectChange = (value: any[]) => {
    const selectedCompanyList: any = value.map((val) => {
      return {
        id: val,
      };
    });
    setSelectedCompanies(selectedCompanyList);
  };

  const addProject: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      id: id,
      companies: selectedCompanies,
      startDate: dayjs(projectStartDate).format(dateFormats.Y_M_D),
      endDate: dayjs(projectEndDate).format(dateFormats.Y_M_D),
    };
    const { error }: any = await projectService.updateProject(newData);
    navigate(`${process.env.PUBLIC_URL}/crm/project/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  const getProject = async (id: any) => {
    const { data, error }: any = await projectService.getProject(id);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    setProjectStartDate(data?.body.startDate);
    setProjectEndDate(data?.body.endDate);
    setSelectedCompanies(
      data?.body.companies.map((company: Company) => {
        return company?.id;
      })
    );
    const newData = {
      ...data?.body,
      companies: data?.body.companies.map((company: Company) => {
        return company.id;
      }),
    };
    form.setFieldsValue(newData);
  };

  useEffect(() => {
    getCompanies();
    getProject(id);
  }, [id]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("CrmPages.Project")}
        parent={t("CrmPages.Project")}
        subParent={t("CrmPages.ProjectPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/crm/project/list`}
        title={t("CrmPages.AddProject")}
      />
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <AntForm
                  className="theme-form"
                  onFinish={addProject}
                  autoComplete="off"
                  form={form}
                >
                  <Row>
                    <Col>
                      <Label>{t("Common.name") + " *"}</Label>
                      <AntForm.Item
                        name="name"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t("FormValidations.CrmPages.NameRequired"),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.name")} *`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Label>{t("CrmPages.ProjectCode") + " *"}</Label>
                      <AntForm.Item
                        name="projectCode"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.ProjectCodeRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("CrmPages.ProjectCode")} *`} />
                      </AntForm.Item>
                    </Col>
                    <Col md={6}>
                      <Label>{t("CrmPages.ProjectLocation") + " *"}</Label>
                      <AntForm.Item
                        name="projectLocation"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.ProjectLocationRequired"
                            ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={`${t("CrmPages.ProjectLocation")} *`}
                        />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AntForm.Item
                        name="startDate"
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <Label>{t("Common.startDate") + " *"}</Label>
                        <DatePicker
                          placeholder={t("Common.startDate") + " *"}
                          format={dateFormats.Y_M_D}
                          className={`form-control`}
                          required
                          value={dayjs(projectStartDate)}
                          onChange={(e: any) => {
                            setProjectStartDate(e);
                          }}
                        />
                      </AntForm.Item>
                    </Col>
                    <Col md={6}>
                      <AntForm.Item
                        name="endDate"
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <Label>{t("Common.endDate") + " *"}</Label>
                        <DatePicker
                          placeholder={t("Common.endDate") + " *"}
                          format={dateFormats.Y_M_D}
                          className={`form-control`}
                          required
                          value={dayjs(projectEndDate)}
                          onChange={(e: any) => {
                            setProjectEndDate(e);
                          }}
                        />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Label>{t("Common.manager") + " *"}</Label>
                      <AntForm.Item
                        name="managerId"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.ManagerRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.manager")} *`} />
                      </AntForm.Item>
                    </Col>
                    <Col md={6}>
                      <Label>{t("Common.Company") + " *"}</Label>
                      <AntForm.Item
                        name="companies"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.CompanyRequired"
                            ),
                          },
                        ]}
                      >
                        <AntSelect
                          onChange={handleCompanySelectChange}
                          mode="multiple"
                          size="large"
                          options={companyList.map((company) => {
                            return { label: company?.name, value: company?.id };
                          })}
                        ></AntSelect>
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.Save")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/crm/contact/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </AntForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
