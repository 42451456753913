import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DropdownWithHeader from "../../../../../Utils/CommonComponents/DashboardsCommon/DropdownWithHeader";
import { Notifications } from "../../../../../Utils/Constants";
import {
  monthlyDropdownList,
  notificationCardData,
} from "../../../../../Data/DashboardsData/DefaultData";
import { H5, LI, P, UL } from "../../../../../AbstractElements";

export default function NotificationCard() {
  const { t } = useTranslation();

  const [openStates, setOpenStates] = useState(
    Array(notificationCardData.length).fill(false)
  );
  const toggle = (index: number) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };
  return (
    <Col xl={12} md={12} className="notification-card proorder-xl-5">
      <Card className="custom-scrollbar">
        <DropdownWithHeader
          headerClass="card-no-border pb-0"
          heading={t("Dashboard.RecentTransactions")}
          dropDownList={monthlyDropdownList}
          dropDownClass="icon-dropdown"
          dropDownIcon={true}
        />
        <CardBody>
          <UL className="notification-box simple-list">
            {notificationCardData.map((item) => (
              <LI className="d-flex py-3" key={item.id}>
                <div className="flex-shrink-0">
                  <i className="icofont icofont-pencil-alt-5"></i>
                </div>
                <div className="flex-grow-1">
                  <Link to={``}>
                    <H5>{item.title}</H5>
                  </Link>
                  <P>{"1 saat önce"}</P>
                </div>
                {/* <Dropdown
                  isOpen={openStates[item.id]}
                  toggle={() => toggle(item.id)}
                  className="icon-dropdown"
                >
                  <DropdownToggle color="transparent">
                    <i className="icon-more-alt" />
                  </DropdownToggle>
                  <DropdownMenu end={true}>
                    {monthlyDropdownList.map((item, index) => (
                      <DropdownItem key={index}>{item}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown> */}
              </LI>
            ))}
          </UL>
        </CardBody>
      </Card>
    </Col>
  );
}
